import React, { Component } from 'react';
import { toast } from 'react-toastify';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Config from '../config'
import APICall from '../apiCall/api';

import './homework.css';

class Homework extends Component {
    constructor(props) {
        super(props);
        const defaultSubmissionDate = new Date();
        defaultSubmissionDate.setDate(defaultSubmissionDate.getDate() + 1);

        this.state = {
            subjectList: [],
            startDate: defaultSubmissionDate,
            scheduleDate: defaultSubmissionDate,
            selectedFile: [],
            classList: [],
            currentSession: {},
            selectedClass: {},
            isFileOrDescSelected: false,
            newHomeWork: null,
            scheduleCheck: false,
            roles: JSON.parse(sessionStorage.getItem('roles'))
        }
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleScheduleDateChange = this.handleScheduleDateChange.bind(this);
        this.toggleSchedule = this.toggleSchedule.bind(this);
        this.apiCall = new APICall();
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    };

    handleScheduleDateChange(date) {
        this.setState({
            scheduleDate: date
        });
    };

    create(e) {
        e.preventDefault();

        const selectedSubject = this.state.subjectList.find(sub => sub._id === this._selectedsubject.value);
        const selectedClass = this.state.classList.find(cls => cls._id === this._selectedOption.value);

        const formData = new FormData();
        [...this.state.selectedFile].map((image) => formData.append('uploader', image));
        formData.append('subject', JSON.stringify(selectedSubject));
        formData.append('selectedDate', this.state.startDate);
        formData.append('description', this._description.value);
        formData.append('teacherId', sessionStorage.getItem('id'));
        formData.append('class', JSON.stringify(selectedClass));
        formData.append('session', JSON.stringify(this.state.currentSession));
        formData.append('scheduleDate', this.state.scheduleDate);
        formData.append('scheduleTime', this._selectedTime.value);
        formData.append('isScheduleOn', this.state.scheduleCheck);

        if ((this.state.selectedFile ||
            this._description.value !== '') &&
            this.state.currentSession._id) {
            this.setState({ isFileOrDescSelected: false });

            const url = `${Config.API}student/add-home-work`;
            this.apiCall.post(url, formData).then((data) => {
                this.clearForm();
                this.setState({ newHomeWork: data });
                toast.info('Success: Created!');
            }).catch((err) => {
                console.log(err);
                toast.error('Error:Failed!');
            });
        } else {
            this.setState({ isFileOrDescSelected: true });
        }
    }

    delete() {
        const url = `${Config.API}student/delete-home-work`;
        this.apiCall.post(url, this.state.newHomeWork).then(() => {
            this.setState({ newHomeWork: null });
            toast.info('Success: Deleted!');
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
    }

    componentDidMount() {
        this.getclass();
        this.getSession();
        this.getSubject();
    }

    clearForm() {
        const defaultSubmissionDate = new Date();
        defaultSubmissionDate.setDate(defaultSubmissionDate.getDate() + 1);
        this._selectedOption.value = "";
        this._selectedOption.value = "";
        this._description.value = "";
        this._selectedsubject.value = "";
        this._selectedTime.value = "7:00";
        this.setState({
            startDate: defaultSubmissionDate, selectedFile: [],
            scheduleDate: defaultSubmissionDate, scheduleCheck: false
        });

    }


    getSession() {
        const url = `${Config.API}session/get`;
        this.apiCall.get(url).then((data) => {
            const currentSession = data[0];
            this.setState({ currentSession });
        }).catch((err) => {
            console.log(err)
        });
    }

    getclass() {
        const url = `${Config.API}class/get`;
        this.apiCall.get(url).then((data) => {
            const assignClassIds = [];
            this.state.roles.forEach(r => {
                if(r.assignClass){
                    assignClassIds.push(r.assignClass._id);
                }
            });
            const classList = assignClassIds.length > 0 ? data.filter(d=> assignClassIds.includes(d._id))  : data;

            this.setState({ classList });
        }).catch((err) => {
            console.log(err)
        });
    }


    getSubject() {
        const url = `${Config.API}subject/get`;
        this.apiCall.get(url).then((data) => {
            const subjectList = data;
            this.setState({ subjectList });
        }).catch((err) => {
            console.log(err)
        });
    }

    onChangeHandler(event) {
        this.setState({
            selectedFile: event.target.files
        })

    }

    toggleSchedule() {
        const toggleValue = this.state.scheduleCheck;
        this.setState({ scheduleCheck: !toggleValue });
    }

    render() {
        return (
            <div className="container page_padding">
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="collapseNine">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseSeven1" aria-expanded="true" aria-controls="collapseSeven">
                                    Homework
                            </button>
                            </h5>
                        </div>

                        <div id="collapseSeven1" className="collapse hide" aria-labelledby="collapseNine" data-parent="#accordion">
                            <div className="card-body custom-card-body">
                                <form onSubmit={this.create}>
                                    <div className="form-group">
                                        <label className="col-form-label-sm font-weight-bold">Session- {this.state.currentSession.text}</label>
                                    </div>
                                    <div className="form-group">
                                        <div className="custom-control custom-checkbox">
                                            <input className="custom-control-input" type="checkbox" id="homeworkScheduleId" checked={this.state.scheduleCheck} onChange={() => this.toggleSchedule()} />
                                            <label className="custom-control-label" htmlFor="homeworkScheduleId">To create schedule homework click me</label>
                                        </div>
                                    </div>
                                    <div className="form-group" hidden={!this.state.scheduleCheck}>
                                        <label className="padding-10-right">Select Schedule Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.scheduleDate}
                                            onChange={this.handleScheduleDateChange}
                                            minDate={new Date()}
                                        />
                                        <select className="custom-select col-3 time-dropdown"
                                            ref={(v) => this._selectedTime = v}>
                                            {this.timeItem()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select className="custom-select"
                                            ref={(v) => this._selectedOption = v} required>
                                            <option value=''>Select Class</option>
                                            {this.classItem()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select className="custom-select"
                                            ref={(v) => this._selectedsubject = v} required>
                                            <option value=''>Select Subject</option>
                                            {this.subjectItem()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label className="padding-10-right">Select Submission Date</label>
                                        <DatePicker className="form-control"
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            minDate={new Date()}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea id="homeworktextAreaId" rows="10" cols="50" className="form-control"
                                            placeholder="Enter Homework" ref={(v) => this._description = v} />
                                        {this.state.isFileOrDescSelected &&
                                            <label style={{ color: "#db3e00" }}>Upload a file or Fill description!</label>}
                                    </div>
                                    <div className="form-group">
                                        <label className="padding-10-right">Upload Homework's Photo</label>
                                        <input type="file" name="file" onChange={this.onChangeHandler} multiple />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                                {this.state.newHomeWork && <section className="row">
                                    {this.renderNewHomeWork()}
                                </section>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderNewHomeWork() {
        const item = this.state.newHomeWork;
        return (
            <div className="alert alert-info alert-dismissible fade show col-8 page_left_margin" role="alert" key={item._id}>
                <div className="row">
                    <h6 className="col-12">Class - {item.class.text}</h6>
                    <h6 className="col-12">Subject - {item.subject.text}</h6>
                    <h6 className="col-12">Submission Date - {item.submissionDate}</h6>
                    <h6 className="col-12">Description - {item.description}</h6>
                    <h6 className="col-12">Uploaded File Name - {this.getUploadeImageList(item.imageNames)}</h6>
                    <button className="close" aria-label="Close" onClick={this.delete}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
        )
    }

    getUploadeImageList(imageNames) {
        return <ul>
            {imageNames.map((img, index) => (
                <li key={index}>{img}</li>
            ))}
        </ul>
    }

    classItem() {
        return this.state.classList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    subjectItem() {
        return this.state.subjectList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    timeItem() {
        const times = ['7:00', '8:00', '9:00', '10:00', '11:00', '12:00',
            '13:00', '14:00', '15:00', '16:00', '17:00', '18:00',
            '19:00', '20:00', '21:00', '22:00', '23:00']
        return times.map((item) => {
            return (
                <option value={item} key={item}>{item}</option>
            )
        });
    }
}

export default Homework;