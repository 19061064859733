import React, { useState } from 'react'
import { useEffect } from 'react'
import './announcement.css'
const AnnouncementNew = () => {
    const [announcementData,setannouncementData] = useState([]);
    const handleFetch = async(url)=>{
        console.log('api call new')
        const response = await fetch(url).then((response)=> response.json());
        setannouncementData(response)
        console.log('announcementdata',response)
    }

    const APIKEY = "http://198.12.156.30:8080/announcment/get";

    useEffect(()=>{
       handleFetch(APIKEY);
    },[])

  return (

<div className="testinomials-container">
                    <h3>ANNOUNCMENT</h3>
      <h1 style={{margin:'2px'}}> LATEST ANNOUNCMENTS</h1>
      {announcementData && announcementData.map((item)=>{
        return(
           <div className='announcementContainer'>
            <div className='announcement'>
            <h5 className='announcementIcon'>🔔</h5>
            <h6 className='announcementText'>  {item.text}</h6>
           </div>
           </div>
        )
       })}
    </div>
  )
}

export default AnnouncementNew

