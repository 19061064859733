import React, { Component } from 'react';
import { toast } from 'react-toastify';

import Config from '../config'
import APICall from '../apiCall/api';

import './login.css';

class ChangePassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validationErrors: []
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.apiCall = new APICall();
    }

    validateForm() {
        return (this._newPassword && this._newPassword.length > 0 && this._confirmPassword && this._confirmPassword.length > 0);
    }

    checkPasswordAndConfirmPassword() {
        return (this._newPassword.value === this._confirmPassword.value);

    }

    handleSubmit(e) {
        e.preventDefault();
        console.log('pass', this._newPassword.value, this._confirmPassword.value);
        if (this._newPassword.value.length < 5) {
            this.setState({ validationErrors: ['Passoword should contain 6 characters!'] });
        } else if (!this.checkPasswordAndConfirmPassword()) {
            this.setState({ validationErrors: ['Passoword and Confirm Password not match!'] });
        } else {
            const id = sessionStorage.getItem('id');
            this.setState({ validationErrors: [] });
            const url = `${Config.API}users/changePassword`;
            const options = {
                id,
                password: this._newPassword.value
            }
            this.apiCall.post(url, options).then((res) => {
                this.setState({ validationErrors: ['Your Passoword has been changed!'] });
            }).catch((err) => {
                console.log(err);
                toast.error('Error:Failed!');
            });
        }


    }

    render() {
        return (
            <section className="container login-container">
                <article className="row justify-content-md-center">
                    <article className="col-md-6 login-form-1">
                        <h3>Change Passoword</h3>
                        <form onSubmit={this.handleSubmit}>
                            {this.state.validationErrors.map((err, index) => (
                                <div className="form-group" key={index}>
                                    <label className="col-sm col-form-label text-danger">{err}</label>
                                </div>
                            ))}

                            <div className="form-group">
                                <input type="password" id="newPassword" className="form-control" placeholder="New Password"
                                    required ref={(v) => this._newPassword = v} />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Confirm Password" ref={(v) => this._confirmPassword = v} required
                                    id="confirmPassword" />
                            </div>
                            <div className="form-group">
                                <input type="submit" className="btnSubmit" value="Change" />
                            </div>
                            <div className="form-group">
                                <a className="nav-link js-scroll-trigger" href="/login">login</a>
                            </div>
                        </form>

                    </article>
                </article>
            </section>
        );
    }
}

export default ChangePassword;