import React, { Component } from 'react';
import { toast } from 'react-toastify';

import Config from '../config'
import APICall from '../apiCall/api';

class UploadGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryList: [],
            imageList: [],
            imageGalleryList: []
        }
        this.handleChange = this.handleChange.bind(this)
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.apiCall = new APICall();
    }

    handleChange(event) {
        this.setState({ imageList: event.target.files });
    }

    create(e) {
        e.preventDefault();
        if (this.selectedOption.value !== '-1' && !Array.isArray(this.state.imageList)) {
            const formData = new FormData();
            [...this.state.imageList].map((image) => formData.append('uploader', image));
            formData.append('galleryName', this.selectedOption.value);
            const url = `${Config.API}galleryImage/create`;
            this.apiCall.post(url, formData).then((data) => {
                this.getGallery();
                this.getImageGallery();
                this.setState({ imageList:[]});
                this.selectedOption.value = '-1';
                toast.info('Success: Created!');
            }).catch((err) => {
                console.log(err);
                toast.error('Error:Failed!');
            });
        }
    }

    componentDidMount() {
        this.getGallery();
        this.getImageGallery();
    }

    render() {
        return (
            <div className="container page_padding">
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="headingTwo">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    Upload Images for Gallery
                    </button>
                            </h5>
                        </div>

                        <div id="collapseTwo" className="collapse hide" aria-labelledby="headingTwo" data-parent="#accordion">
                            <div className="card-body custom-card-body">
                                <form encType="multipart/form-data">
                                    <div className="form-group">
                                        <select className="custom-select" ref={(v) => this.selectedOption = v}>
                                            <option value='-1'>Select GalleryName</option>
                                            {this.galleryItem()}
                                        </select>
                                    </div>

                                    <div className="form-group">
                                        <input type="file" accept="image/*" multiple onChange={this.handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" onClick={this.create}>Submit</button>
                                    </div>
                                </form>
                                {this.getImageGalleryForm()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getImageGalleryForm() {
            return this.state.imageGalleryList.map((imgGallery) => {
            return(<section key={imgGallery.galleryName}>
                <article className="row">
                    <label className="col-sm-12">{imgGallery.galleryName}</label>
                </article>
                <article className="row">
                    {this.getImagesForGallery(imgGallery.galleryImage)}
                </article>
            </section>)
            });
            
        
    }

    getImagesForGallery(Images){
        return Images.map((image) => {
                const imgUrl = `${Config.API}/img/${image.imageName}`;
                return (
                    <div className="alert alert-info alert-dismissible fade show col-sm-2 page_left_margin" role="alert" key={image._id}>
                    <img src={imgUrl} alt="" className="img-thumbnail" key={image._id}/>
                    <button className="close" aria-label="Close" onClick={() => this.delete(image._id, image.imageName)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>)
                
                });

    }

    galleryItem() {
        return this.state.galleryList.map((item) => {
            return (
                <option value={item.name} key={item._id}>{item.name}</option>
            )
        });
    }

    getGallery() {
        const url = `${Config.API}gallery/get`;
        this.apiCall.get(url).then((data) => {
            const galleryList = data;
            this.setState({ galleryList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getImageGallery() {
        const url = `${Config.API}galleryImage/get`;
        this.apiCall.get(url).then((data) => {
            const imageGalleryList = data;
            this.setState({ imageGalleryList });
        }).catch((err) => {
            console.log(err)
        });
    }

    delete(id, name) {
        console.log('dele', id)
        const url = `${Config.API}galleryImage/delete/${id}/${name}`;
        this.apiCall.delete(url).then(() => {
            this.getImageGallery();
            toast.info('Success: Deleted!');
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
        
    }
}

export default UploadGallery;