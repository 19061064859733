import React, { Component } from 'react';
import Config from '../config'
import APICall from '../apiCall/api';
import { Table } from 'react-bootstrap';

class FeeReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessionList: [],
            classList: [],
            categoryList: [],
            currentSessionId: {},
            students: [],
            roles: JSON.parse(sessionStorage.getItem('roles')),
            selectedClass: ''
        }
        this.onClassChangeGetStudent = this.onClassChangeGetStudent.bind(this);
        this.apiCall = new APICall();
    }
    

    componentDidMount() {
        this.getclass();
        this.getCategory();
        this.getSession()
    }

    onClassChangeGetStudent(event) {    
        if (this._selectedClass) {
            this.setState({ selectedClass: this._selectedClass.value });
            this.setState({ currentSessionId: this._selectedSession.value });
            this.getStudentByClass(this._selectedClass.value, this._selectedSession.value);
        }
    }

    getStudentByClass(classId, currentSessionId) {
        if (classId !== '') {
            const url = `${Config.API}student/getByClass`;
            this.apiCall.post(url, { currentSessionId, classId }).then((data) => {
                const students = this.getStudentList(data.students);
                this.setState({ students });
            }).catch((err) => {
                console.log(err)
            });
        } else {
            this.setState({ students: [] });
        }
    }

      calculateTotalDeposit(depositFee) {
        let totalDepositFee = 0.0;
        Object.entries(depositFee)
        .forEach( ([key, value]) => {
            if (key && value.value) {
                totalDepositFee += parseFloat(`${value.value}`);
            }
         })
        return totalDepositFee
    }

    render() {
        const url = `${Config.API}student/downloaddExcelForStudentsDetails`;
        return <div className="page_padding_fee">
            {/* <a type="button" href={url} target="_blank" rel="noopener noreferrer" download>
                <button className="btn btn-outline-primary btn-sm mb-3">Export Students</button>
            </a> */}
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingFour">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                Student By Class
                            </button>
                        </h5>
                    </div>

                    <div id="collapseFour" className="collapse show" aria-labelledby="headingFour" data-parent="#accordion">
                        <div className="card-bodyx">
                            <form>
                                    <div className="form-group">
                                        <select className="custom-select"
                                            ref={(v) => this._selectedSession = v} required onChange={this.onClassChangeGetStudent}>
                                            <option value=''>Select Session</option>
                                            {this.sessionItem()}
                                        </select>
                                    </div>

                                <div className="form-group">
                                    <select className="custom-select"
                                        ref={(v) => this._selectedClass = v} required onChange={this.onClassChangeGetStudent}>
                                        <option value=''>Select Class</option>
                                        {this.classItem()}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <ul className="list-group">
                                        {this.studentList()}
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    }

    classItem() {
        return this.state.classList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    categoryItem() {
        return this.state.categoryList.map((item) => {
            return (
                <option value={item.text} key={item._id}>{item.text}</option>
            )
        });
    }
    showAllDiscount(date_key, data) {
        return (
        <div className='border mt-2'>
            <p>Deposite Date: {date_key}</p>
            <p>Deposite Fee: {data.value}</p>
            <p>Transaction Id: {data.transaction_id}</p>
            <p>Payment Mod: {data.payment_mod}</p>
        </div>
    )}

    studentList() {
        let counter = 1;

        const list = this.state.students.map((item, index) => {
            return (
            <Table striped bordered hover className='mt-3'>
                <thead>
                    <tr>
                        <th>SR NO</th>
                        <th>Student Name</th>
                        <th>Fee Category</th>
                        <th>Registration Fee</th>
                        <th>Admission Fee</th>
                        <th>Term Fee</th>
                        <th>Tuition Fee</th>
                        <th>Transport Fee</th>
                        <th>Previous Dues Fee</th>
                        <th>Discount</th>
                        <th>Deposit Fee History</th>
                        <th>Remaining Fee</th>
                        <th>Total Fee Paid</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{counter}</td><td>{item.name}</td><td>{this._feeCategory?.value}</td>
                    {
                    (this._feeCategory?.value !== 'PPS-EIP' ? (
                        <>
                            <td>{item.fee?.registrationFee || 0}</td>
                            <td>{item.fee?.admissionFees || 0}</td>
                            <td>{item.fee?.termFee || 0}</td>
                        </>): <><td>NA</td><td>NA</td><td>NA</td></>)
                    }
                    <td>{item.fee?.tuitionFees || 0}</td>
                    <td>{item.fee?.transportFees || 0}</td>
                    {
                        (this._feeCategory?.value === 'PPS' ? (
                            <>
                            <td>{item.fee?.previousDuesFee || 0}</td>
                            </>
                        ): <td>NA</td>)
                    }

                    <td>{item.fee?.discount || 0}</td>
                    <td>{
                        item.fee?.depositFee ? Object.keys(item.fee?.depositFee).map((key) => {
                            let depositFee = item.fee.depositFee[key];
                            return <div key={key}>{this.showAllDiscount(key, depositFee)}</div>
                        }) : ""
                        }</td>
                    <td>{item.fee.remainingFee}</td><td>{item.fee.totalFee}</td>
                </tr>
                
                </tbody>
             </Table>
            )
        });

        return list;
    }

    getclass() {
        const url = `${Config.API}class/get`;
        this.apiCall.get(url).then((data) => {
            const assignClassIds = [];
            console.log('this.state.roles: ', this.state.roles)
            this.state.roles.forEach(r => {
                if (r.assignClass) {
                    assignClassIds.push(r.assignClass._id);
                }
            });
            const classList = assignClassIds.length > 0 ? data.filter(d => assignClassIds.includes(d._id)) : data;

            this.setState({ classList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getCategory() {
        const url = `${Config.API}feecategory/get`;
        this.apiCall.get(url).then((data) => {
            const categoryList = data;
            this.setState({ categoryList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getSession() {
        const url = `${Config.API}session/get`;
        this.apiCall.get(url).then((data) => {
            const sessionList = data;
            this.setState({ sessionList });
        }).catch((err) => {
            console.log(err)
        });
    }

    sessionItem() {
        return this.state.sessionList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    getStudentList(data) {
        return data.map(d => d)
    }

    clearFormData() {
    }
}


export default FeeReport;