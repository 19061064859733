import React, { Component } from 'react';
import { toast } from 'react-toastify';

import Config from '../config'
import APICall from '../apiCall/api';

import './attendence.css';

class Attendence extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classList: [],
            currentSession: {},
            students: [],
            selectedClass: '',
            isTodayAttendaceDone: false,
            roles: JSON.parse(sessionStorage.getItem('roles'))
        }
        this.onClassChangeGetStudent = this.onClassChangeGetStudent.bind(this);
        this.addToAttendence = this.addToAttendence.bind(this);
        this.saveAttendence = this.saveAttendence.bind(this);
        this.toggleAttendance =  this.toggleAttendance.bind(this);
        this.apiCall = new APICall();
    }

    componentDidMount() {
        this.getclass();
        this.getSession();
    }

    onClassChangeGetStudent(event) {
        this.setState({ selectedClass: this._selectedClass.value });
        this.getStudentByClass(this._selectedClass.value, this.state.currentSession._id);
    }

    addToAttendence(studentId) {
        const students = this.state.students.map(function (std) {
            if (std.id === studentId) {
                std.present = !std.present;
            }
            return std;
        });
        this.setState({ students });

    }

    toggleAttendance(student){

    }

    getStudentByClass(classId, currentSessionId) {
        if(classId !== ''){
            const url = `${Config.API}student/getByClass`;
            this.apiCall.post(url, { currentSessionId, classId }).then((data) => {
                const students = this.getStudentList(data.students)
                const isTodayAttendaceDone = data.isTodayAttendaceDone;
                this.setState({ students, isTodayAttendaceDone });
            }).catch((err) => {
                console.log(err)
            });
        } else {
            this.setState({ students: [], isTodayAttendaceDone: false });
        }
    }

    saveAttendence(e) {
        e.preventDefault();
        const payload = {
            selectedClass: this.state.selectedClass,
            selectedSession: this.state.currentSession._id,
            teacherId: sessionStorage.getItem("id"),
            attendenceList: this.state.students,

        }
        const url = `${Config.API}student/add-attendence`;
        this.apiCall.post(url, payload).then((data) => {
            console.log('student updated');
            this.setState({isTodayAttendaceDone: true});
            toast.info('Success: Saved!');
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
    }

    render() {
        return <div className="container page_padding">
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingFour">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                Attendence
            </button>
                        </h5>
                    </div>

                    <div id="collapseFour" className="collapse hide" aria-labelledby="headingFour" data-parent="#accordion">
                        <div className="card-body custom-card-body">
                            <form onSubmit={this.saveAttendence}>
                                <div className="form-group">
                                    <label className="col-form-label-sm font-weight-bold">Session- {this.state.currentSession.text}</label>
                                </div>
                                { this.state.isTodayAttendaceDone && <div className="form-group">
                                    <label className="col-form-label-sm font-weight-bold">
                                        Today Attendance is already submitted.
                                    </label>
                                </div>}
                                <div className="form-group">
                                    <select className="custom-select"
                                        ref={(v) => this._selectedClass = v} required onChange={this.onClassChangeGetStudent}>
                                        <option value=''>Select Class</option>
                                        {this.classItem()}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <ul className="list-group">
                                        {this.studentList()}
                                    </ul>
                                </div>
                                <div className="form-group">
                                    <button type="submit" className={`btn btn-primary ${this.state.isTodayAttendaceDone ? " cursorNotAllowed" : ""}`} disabled={this.state.isTodayAttendaceDone}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    classItem() {
        return this.state.classList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    studentList() {
        const list = this.state.students.map((item, index) => {
            return (
                <li className="list-group-item list-group-item-info" key={index}>
                    <div className="form-check row">
                        <label className="form-check-label col-10" htmlFor={item.id}>
                            {`${index + 1}. ${item.name}`}
                        </label>
                        <input className="form-check-input col-2" type="checkbox" value={item.id} id={item.id} checked={item.present} onChange={() => this.addToAttendence(item.id)}/>
                    </div>
                </li>
            )
        });

        return list;
    }

    getclass() {
        const url = `${Config.API}class/get`;
        this.apiCall.get(url).then((data) => {
            const assignClassIds = [];
            this.state.roles.forEach(r => {
                if(r.assignClass){
                    assignClassIds.push(r.assignClass._id);
                }
            });
            const classList = assignClassIds.length > 0 ? data.filter(d=> assignClassIds.includes(d._id))  : data;

            this.setState({ classList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getSession() {
        const url = `${Config.API}session/get`;
        this.apiCall.get(url).then((data) => {
            const currentSession = data[0];
            this.setState({ currentSession });
        }).catch((err) => {
            console.log(err)
        });
    }

    getStudentList(data) {
        return data.map(d => ({ id: d._id, name: d.name, present: true }))
    }

}


export default Attendence;