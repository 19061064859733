import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './menuItem.css';

class MenuItem extends Component{
  routes  = {
    'StudentForm' : '/studentform',
    'FeeReport' : '/feeReport',
    'Attendence' : '/adminattendance',
    'Homework' : '/homework',
    'Session' : '/session',
    'Class' : '/class',
    'Subject' : '/subject',
    'Accountent' : '/accountent',
    'Teacher' : '/teacher',
    'PendingApprovalForDiscount': '/pendingapprovalfordiscount',
    'Gallary' : '/admingallery',
    'UploadGallery' : '/uploadgallery',
    'Announcement' : '/adminannouncement',
    'Query' : '/query',
    'Enquiry' : '/enquiry',
    'ScheduleAnnouncmentByClassOrStudent' : '/scheduleannouncement',
    'StudentByClass' : '/getstudentsbyclass',
    'Appointment':'/appointments',
    'Visitor':'/visitors'
    
  };
    constructor(props){
      super(props);
      this.state = {
        hover:false,
      }
    }
    
    handleHover(){
      this.setState({hover:!this.state.hover});
    }
    
    render(){
      return(
        <div className = 'menu-item-container'>
          <div 
            className = 'menu-item' 
            onMouseEnter={()=>{this.handleHover();}} 
            onMouseLeave={()=>{this.handleHover();}}
            onClick={this.props.onClick}
          >
            <Link className = 'menu-item' to={this.routes[this.props.value]}> {this.props.children} </Link>  
          </div>
      </div>  
      )
    }
  }

  export default MenuItem;