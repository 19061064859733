import React, { Component } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import './App.css';
import history from './history';
import { PrivateRoute } from './helper/privateRoute';
import Login from './admin/login';
import Header from './header/header';
import AdminHeader from './admin/header/header';
import Carousal from './carousal/carousal';
import Home from './home/home';
import AboutUs from './aboutUs/aboutUS';
import Services from './services/services';
import Gallery from './gallery/gallery';
import ContactUs from './contactUs/contactUs';
import Announcment from './announcment/announcment';
import DashBoard from './admin/dashBoard';
import Footer from './userLayout/Footer.js';
import ModalPopUP from './modalPopUp';
import ChangePassword from './admin/changePassword';
import GetAttendece from './admin/getAttendence';
import StudentForm from './admin/studentForm';
import FeeReport from './admin/feeReport';
import AdminGallery from './admin/gallery';
import UploadGallery from './admin/uploadGallery';
import AdminAnnouncment from './admin/announcment';
import AdminAttendence from './admin/attendence';
import Subject from './admin/subject';
import Class from './admin/class';
import Teacher from './admin/teacher';
import Accountent from './admin/accountent';
import PendingApprovalForDiscount from './admin/pendingApprovalForDiscount';
import Homework from './admin/homework';
import Session from './admin/session';
import Query from './admin/query';
import Enquiry from './admin/enquiry';
import StudentByClass from './admin/studentByClass';
import ScheduleAnnouncmentByClassOrStudent from './admin/scheduleAnnouncmentByClassOrStudent';
import Appointment from './admin/Appointment';
import Visitor from './admin/Visitor'
import AppointmentApp from './AppointmentApp';
import APICall from './apiCall/api';
import Config from './config'
import VisitorForm from './visitor/visitorForm';

// newly added components
import HeaderNew from './userLayout/HeaderNew'
import MainHome from './userLayout/MainHome';

class App extends Component {

  componentDidMount() {
    const apiCall = new APICall();
    const url = `${Config.API}users/validateToken`;
    apiCall.get(url).then((d) => {
      sessionStorage.setItem('isValidToken', true);
    }).catch((err) => {
      console.log('err', err.response);
      if (err.response && err.response.status === 401) {
        sessionStorage.setItem('isValidToken', false);
      }
    });
  }

  render() {
    return (
      <Router history={history}>

        <Switch>

          <Route exact path="/" component={this.getUserLayout} />
          <Route exact path="/login" component={this.getLoginLayout} />
          <PrivateRoute exact path="/dashboard" component={this.getAdminLayout} />
          <PrivateRoute exact path="/change-password" component={this.getChangePasswordLayout} />
          <Route exact path="/get-attendenece" component={GetAttendece} />
          <PrivateRoute exact path="/studentform" component={this.getStudentForm} />
          <PrivateRoute exact path="/feeReport" component={this.getFeeReport} />
          <PrivateRoute exact path="/admingallery" component={this.getAdminGallery} />
          <PrivateRoute exact path="/uploadgallery" component={this.getUploadGallery} />
          <PrivateRoute exact path="/adminannouncement" component={this.getAdminAnnouncment} />
          <PrivateRoute exact path="/adminattendance" component={this.getAdminAttendence} />
          <PrivateRoute exact path="/subject" component={this.getSubject} />
          <PrivateRoute exact path="/class" component={this.getClass} />
          <PrivateRoute exact path="/teacher" component={this.getTeacher} />
          <PrivateRoute exact path="/accountent" component={this.getAccountent} />
          <PrivateRoute exact path="/pendingapprovalfordiscount" component={this.getPendingApprovalForDiscount} />
          <PrivateRoute exact path="/homework" component={this.getHomework} />
          <PrivateRoute exact path="/session" component={this.getSession} />
          <PrivateRoute exact path="/query" component={this.getQuery} />
          <PrivateRoute exact path="/enquiry" component={this.getEnquiry} />
          {/* <PrivateRoute exact  path="/appform" component={Appoinment_form} /> */}
          <Route exact path="/appApp" component={AppointmentApp} />
          <PrivateRoute exact path="/appointments" component={this.getAppointment} />
          <Route exact path="/visitor" component={VisitorForm} />
          <PrivateRoute exact path="/visitors" component={this.getvisitorForm} />
          <PrivateRoute exact path="/getstudentsbyclass" component={this.getStudentByClass} />
          <PrivateRoute exact path="/scheduleannouncement" component={this.getScheduleAnnouncmentByClassOrStudent} />

        </Switch>
      </Router>
    );
  }
  getvisitorForm(){
    return (
      <div className="App">
        <AdminHeader />
        <Visitor/>
        <Footer />
      </div>
    );
  }
  getStudentForm() {
    return (
      <div className="App">
        <AdminHeader />
        <StudentForm />
        <Footer />
      </div>
    );
  }
  getFeeReport() {
    return (
      <div className="App">
        <AdminHeader />
        <FeeReport />
        <Footer />
      </div>
    );
  }

  getAdminGallery() {
    return (
      <div className="App">
        <AdminHeader />
        <AdminGallery />
        <Footer />
      </div>
    );
  }

  getUploadGallery() {
    return (
      <div className="App">
        <AdminHeader />
        <UploadGallery />
        <Footer />
      </div>
    );
  }

  getStudentByClass() {
    return (
      <div className="App">
        <AdminHeader />
        <StudentByClass />
        <Footer />
      </div>
    );
  }

  getAdminAnnouncment() {
    return (
      <div className="App">
        <AdminHeader />
        <AdminAnnouncment />
        <Footer />
      </div>
    );
  }

  getAdminAttendence() {
    return (
      <div className="App">
        <AdminHeader />
        <AdminAttendence />
        <Footer />
      </div>
    );
  }

  getAppointment() {
    return (
      <div className="App">
        <AdminHeader />
        <Appointment />
        <Footer />
      </div>
    );
  }

  getSubject() {
    return (
      <div className="App">
        <AdminHeader />
        <Subject />
        <Footer />
      </div>
    );
  }

  getClass() {
    return (
      <div className="App">
        <AdminHeader />
        <Class />
        <Footer />
      </div>
    );
  }

  getTeacher() {
    return (
      <div className="App">
        <AdminHeader />
        <Teacher />
        <Footer />
      </div>
    );
  }
  
  getAccountent() {
    return (
      <div className="App">
        <AdminHeader />
        <Accountent />
        <Footer />
      </div>
    );
  }

  getPendingApprovalForDiscount() {
    return (
      <div className="App">
        <AdminHeader />
        <PendingApprovalForDiscount />
        <Footer />
      </div>
    );
  }

  getHomework() {
    return (
      <div className="App">
        <AdminHeader />
        <Homework />
        <Footer />
      </div>
    );
  }

  getSession() {
    return (
      <div className="App">
        <AdminHeader />
        <Session />
        <Footer />
      </div>
    );
  }

  getQuery() {
    return (
      <div className="App">
        <AdminHeader />
        <Query />
        <Footer />
      </div>
    );
  }

  getEnquiry() {
    return (
      <div className="App">
        <AdminHeader />
        <Enquiry />
        <Footer />
      </div>
    );
  }

  getScheduleAnnouncmentByClassOrStudent() {
    return (
      <div className="App">
        <AdminHeader />
        <ScheduleAnnouncmentByClassOrStudent />
        <Footer />
      </div>
    );
  }

  // getUserLayout() {
  //   return (
  //     <div className="App">
  //       <ModalPopUP show={true} />
  //       <Header />
  //       <Carousal />
  //       <Home />
  //       <AboutUs />
  //       <Services />
  //       <Gallery />
  //       <ContactUs />
  //       <Announcment />
  //       <Footer />
  //     </div>
  //   );
  // }

  getUserLayout() {
    return (
      <div className="App">
        <ModalPopUP show={true} />
        <HeaderNew />
        <MainHome />
      </div>
    );
  }

  getAdminLayout() {
    return (
      <div className="App">
        <AdminHeader />
        <DashBoard />
        <Footer />
      </div>
    );
  }

  getLoginLayout() {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }

  getChangePasswordLayout() {
    return (
      <div className="App">
        <ChangePassword />
      </div>
    );
  }
}

export default App;
