import React, { Component } from 'react';
import './contactUs.css';

class ContactUs extends Component {
    render() {
        return (
            <section className="page_padding section_background_color" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="section-heading text-center">Let's Get In Touch!</h3>
                            <hr className="page_hr" />
                            <p className="mb-4 section_paragraph">
                                Ready to start with us? That's great! Give us a call or send us an email and we will get back to you as soon as
                                possible!
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-lg-12">
                                    <h3 className="section-heading text-center">Let's Get In Touch!</h3>
                                    <hr className="page_hr" />
                                    <p className="mb-4 section_paragraph">
                                        Ready to start with us? That's great! Give us a call or send us an email and we will get back to you as soon as
                                        possible!
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 ml-auto text-center">
                                    <i className="fas fa-phone icon_color fa-2x mb-3 sr-contact-1"></i>
                                    <p className="bold">7276612890 | 9579383966</p>
                                </div>
                                <div className="col-lg-6 mr-auto text-center">
                                    <i className="fas fa-envelope icon_color fa-2x mb-3 sr-contact-2"></i>
                                    <p>
                                        <a href="mailto:Admin@pioneerpublicschool.in" className="black_color bold">Admin@pioneerpublicschool.in</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <iframe title="myFrame" id="gmap_canvas" src="https://maps.google.com/maps?q=Pioneer%20Public%20School&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default ContactUs;