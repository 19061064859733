import React, { Component } from 'react';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import image from './2.jpg';

toast.configure();

class DashBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roles: JSON.parse(sessionStorage.getItem('roles'))
        }
    }

    render() {
        return (
            <section className="container-fluid">
                <article className="welcome-page section-heading">
                    <span>WELCOME</span>
                    <br />
                    <span>TO </span>
                    <br />
                    <span>PIONEER PUBLIC SCHOOL </span>

                    <img src={image} className="d-block w-100" alt=""></img>
                </article>
            </section>
        );
    }
}

export default DashBoard;