import React from 'react';
import './ImageGrid.css';
import gridImg1 from './assets/grid/1.jpg'
import gridImg2 from './assets/grid/2.jpg'
import gridImg4 from './assets/grid/4.jpeg'
import gridImg5 from './assets/grid/5.jpeg'

const ImageGrid = () => {
  return (
    <div className="image-grid">
      <img src={gridImg1} alt="Image 1" />
      <img src={gridImg2} alt="Image 2" />
      <img src={gridImg4} alt="Image 4" />
      <img src={gridImg5} alt="Image 5" />
    </div>
  );
};

export default ImageGrid;
