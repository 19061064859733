import React, { Component } from 'react';
import './menu.css';

class Menu extends Component {
    constructor(props){
      super(props);
      this.state={
        open: this.props.open? this.props.open:false,
      }
    }
      
    componentWillReceiveProps(nextProps){
      if(nextProps.open !== this.state.open){
        this.setState({open:nextProps.open});
      }
    }
    
    render(){
      const styles={
        container: {
          height: this.state.open? '': 0,
          border: this.state.open? '1px solid black' : ''          
        }
      }
      return(
        <div style={styles.container} className = 'menu-container'>
          {
            this.state.open?
              <div className = 'menu-list'>
                {this.props.children}
              </div>:null
          }
        </div>
      )
    }
  }

  export default Menu;