import axios from 'axios';

class APICall {

    async get(url) {
        try {
           const config = { "headers": {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}};
           return axios.get(url, config).then((result) => {
               return result.data;
            });
        } catch (err) {
            return err;
        }

    }

    async post(url, options) {
        try {
          const config = { "headers": {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}};  
          const { data } = await axios.post(url, options, config);
           return data;
        } catch (err) {
            console.log(err);
            return err;
        }
    }

    async put(url, options) {
        try {
            const config = { "headers": {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}};  
            await axios.put(url, options, config);
        } catch (err) {
            return err;
        }
    }

    async patch(url, options) {
        try {
            const config = { "headers": {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}};  
            await axios.patch(url, options, config);
        } catch (err) {
            return err;
        }
    }
    

    async delete(url) {
        try {
            const config = { "headers": {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}};  
            await axios.delete(url, config);
        } catch (err) {
            return err;
        }
    }

    async approve(url) {
        try {
            const config = { "headers": {"Authorization" : `Bearer ${sessionStorage.getItem('token')}`}};  
            await axios.post(url, config);
        } catch (err) {
            return err;
        }
    }
}

export default APICall;