import React, { Component } from 'react';

import Config from '../config'
import APICall from '../apiCall/api';

class Query extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryList: []
        }
        this.apiCall = new APICall();
    }

    componentDidMount() {
        this.getQuery();
    }

    render() {
        return (
            <div className="container page_padding">
                <div id="queryId">
                    <div className="card">
                        <div className="card-header" id="queryheading">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapsequery" aria-expanded="true" aria-controls="collapsequery">
                                    Query
                            </button>
                            </h5>
                        </div>

                        <div id="collapsequery" className="collapse hide" aria-labelledby="queryheading" data-parent="#queryId">
                            <div className="card-body custom-card-body">
                                    {this.QueryItem()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    QueryItem() {        
        return this.state.queryList.map((item, index) => {
            return (
                <section className="row" key={index}>
                <div className="alert alert-info alert-dismissible fade show col-10 page_left_margin" role="alert" key={item._id}>
                   {this.getDateInFormat(item.createdDate)}  | {item.mobileNumber} <br/> 
                   {item.text}
                </div>
                </section>
            )
        });
    }

   addQueryOwner (queryType) {
        return queryType === "Student" ? "Teacher" : queryType
   }

    getDateInFormat(createdDate) {
        const dateTime = new Date(createdDate);
        const year = dateTime.getFullYear();
        const month= dateTime.getMonth() + 1;
        const day = dateTime.getDate();
        return `${day}-${month}-${year}`;
    }

    getQuery(){
        const url = `${Config.API}query/getByMobile`;
        this.apiCall.get(url).then((data) => {
            const queryList = data;
            this.setState({ queryList });
        }).catch((err) => {
            console.log(err)
        });
    }
}

export default Query;