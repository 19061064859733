import React, { Component } from 'react';

import Config from '../config'
import APICall from '../apiCall/api';

class Enquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            enquiryList: []
        }
        this.apiCall = new APICall();
    }

    componentDidMount() {
        this.getEnquiry();
    }

    render() {
        return (
            <div className="container page_padding">
                <div id="enquiryid">
                    <div className="card">
                        <div className="card-header" id="enquiryheading">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseenquiry" aria-expanded="true" aria-controls="collapseenquiry">
                                    Customer Enquiry
                            </button>
                            </h5>
                        </div>

                        <div id="collapseenquiry" className="collapse hide" aria-labelledby="enquiryheading" data-parent="#enquiryid">
                            <div className="card-body custom-card-body">
                                {this.EnquiryItem()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    EnquiryItem() {
        console.log('this.state.enquiryList', this.state.enquiryList);

        return this.state.enquiryList.map((item, index) => {
            return (
                <section className="row" key={index}>
                    <div className="alert alert-info alert-dismissible fade show col-10 page_left_margin" role="alert" key={item._id}>
                        {this.getDateInFormat(item.createdDate)} - {item.name} - {item.phoneno} - {item.query}
                    </div>
                </section>
            )
        });
    }

    getDateInFormat(createdDate) {
        const dateTime = new Date(createdDate);
        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1;
        const day = dateTime.getDate();
        return `${day}-${month}-${year}`;
    }

    getEnquiry() {
        const url = `${Config.API}contactus/getAll`;
        this.apiCall.get(url).then((data) => {
            const enquiryList = data;
            this.setState({ enquiryList });
        }).catch((err) => {
            console.log(err)
        });
    }
}

export default Enquiry;