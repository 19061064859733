import React, { Component } from 'react';
import { toast } from 'react-toastify';

import Config from '../config'
import APICall from '../apiCall/api';

class Subject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subjectList: []
        }
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.apiCall = new APICall();
    }

    create(e) {
        e.preventDefault();
        if (this._newSubject.value !== '') {
            const url = `${Config.API}subject/create`;
            const options = {
                text: this._newSubject.value
            };
            this.apiCall.post(url, options).then((data) => {
               this.getSubject();
               this._newSubject.value = "";
               toast.info('Success: Created!');
            }).catch((err) => {
                console.log(err);
                toast.error('Error:Failed!');
            });
        }
    }

    delete(id) {
        const url = `${Config.API}subject/delete/${id}`;
        this.apiCall.delete(url).then(() => {
            this.getSubject();
            toast.info('Success: Deleted!');
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });  
    }

    componentDidMount() {
        this.getSubject();
    }

    render() {
        return (
            <div className="container page_padding">
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="headingThree">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                    Subject
                            </button>
                            </h5>
                        </div>

                        <div id="collapseSix" className="collapse hide" aria-labelledby="headingThree" data-parent="#accordion">
                            <div className="card-body custom-card-body">
                                <form>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="textmessage1" placeholder="Enter Subject" ref={(v) => this._newSubject = v}/>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" onClick={this.create}>Submit</button>
                                    </div>
                                </form>
                                <section className="row">
                                    {this.renderSubjects()}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSubjects() {
        return this.state.subjectList.map((item) => {
            return (
                <div className="alert alert-info alert-dismissible fade show col-sm-2 page_left_margin" role="alert" key={item._id}>
                    {item.text}
                    <button className="close" aria-label="Close" onClick={() => this.delete(item._id)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        });
    }

    getSubject() {
        const url = `${Config.API}subject/get`;
        this.apiCall.get(url).then((data) => {
            const subjectList = data;
            this.setState({ subjectList });
        }).catch((err) => {
            console.log(err)
        });
    }
}

export default Subject;