import React, { Component } from 'react';
import './menuButton.css';

class MenuButton extends Component {
    constructor(props){
      super(props);
      this.state={
        open: this.props.open? this.props.open:false,
        color: this.props.color? this.props.color:'black',
      }
    }
  
    componentWillReceiveProps(nextProps){
      if(nextProps.open !== this.state.open){
        this.setState({open:nextProps.open});
      }
    }
    
    handleClick(){
    this.setState({open:!this.state.open});
    }
    
    render(){
      return(
        <div className = 'menu-button-container menu-bar-toggler' 
          onClick={this.props.onClick ? this.props.onClick: 
            ()=> {this.handleClick();}}>
          <span className="menu-bar-toggler-icon"></span>
        </div>
      )
    }
  }

export default MenuButton;