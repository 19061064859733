import React, { Component } from 'react';
import { toast } from 'react-toastify';

import Config from '../config'
import APICall from '../apiCall/api';

class Accountent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountentList: []
        }
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.apiCall = new APICall();
    }

    create(e) {
        e.preventDefault();
        const options = this.makeCreateAccountent(
            this._firstName.value.trim(),
            this._middleName.value,
            this._lastName.value.trim(),
            this._phone.value.trim(),
            this._email.value.trim()
        );

        const url = `${Config.API}users/sign-up`;
        this.apiCall.post(url, options).then((data) => {
            if(data.data === 'email exist'){
                toast.info(data.data);
            } else {
                this.assignAccountentRole(data.data);
                this.getAllAccountents();
                console.log('userUpdated', data);
            }
            
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
    }

    delete(id) {
        const url = `${Config.API}users/delete/${id}`;
        this.apiCall.delete(url).then(() => {
            this.getAllAccountents();
            toast.info('Success: Deleted!');
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
    }

    getAllAccountents() {
        const url = `${Config.API}users/getUsersById/4`;
        this.apiCall.get(url).then((res) => {
            const accountentList = res.data;
            this.setState({ accountentList });
        }).catch((err) => {
            console.log(err)
        });
    }

    componentDidMount() {
        this.getAllAccountents();
    }


    render() {
        return (
            <div className="container page_padding">
                <div id="accountentId">
                    <div className="card">
                        <div className="card-header" id="accountentHeading">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseAccountent" aria-expanded="true" aria-controls="collapseAccountent">
                                    Accountent
                            </button>
                            </h5>
                        </div>

                        <div id="collapseAccountent" className="collapse show" aria-labelledby="accountentHeading" data-parent="#accountentId">
                            <div className="card-body custom-card-body">
                                <form onSubmit={this.create}>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="_firstName" placeholder="Enter First Name" ref={(v) => this._firstName = v} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="_middleName" placeholder="Enter Middle Name" ref={(v) => this._middleName = v} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="_lastName" placeholder="Enter Last Name" ref={(v) => this._lastName = v} required/>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" id="_email" placeholder="Enter Email" ref={(v) => this._email = v} required/>
                                    </div>
                                    <div className="form-group">
                                        <input type="tel" className="form-control" pattern="^\d{10}$" id="_phone" placeholder="Enter 10 digit Phone" ref={(v) => this._phone = v} required/>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                                <section className="row">
                                    {this.renderAccountentList()}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAccountentList() {
        return this.state.accountentList.map((item) => {
            return (
                <div className="alert alert-info alert-dismissible fade show col-3 page_left_margin" role="alert" key={item.id}>
                    {`${item.firstName} ${item.middleName ? item.middleName : ''} ${item.lastName}`}
                    <button className="close" aria-label="Close" onClick={() => this.delete(item.id)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        });
    }

    makeCreateAccountent(firstName, newMiddleName, lastName, phone, email) {
        const middleName = newMiddleName ? newMiddleName.trim() : '';
        return {
            firstName,
            middleName,
            lastName,
            phone,
            email
        }
    }

    assignAccountentRole(user) {
        const url = `${Config.API}users/assignRoles`;
        const options = {
            id: user._id,
            roles: [
                {
                    roleId: 4,
                    roleName: "Accountent"
                }
            ]
        }
        
        this.apiCall.post(url, options).then((data) => {
            this.clearForm();
            toast.info('Success: Created!');
        }).catch((err) => {
            console.log(err)
        });
    }

    clearForm() {
        this._firstName.value = "";
        this._middleName.value = "";
        this._lastName.value = "";
        this._email.value = "";
        this._phone.value = "";
        this._email.value = ""
    }
}

export default Accountent;