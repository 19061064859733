import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';

import Config from '../config'
import APICall from '../apiCall/api';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 250,
    },
}));
export default function Appoinment_form(props) {
    //   let [show1,setShow] = useState(props.show)
    let apiCall = new APICall();
    let show1 = props.show
    let [startdate, setdate] = useState(new Date())
    let [time, setonchange] = useState()
    let [a, seta] = useState([])
    let slots = ["09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "02:00", "02:30", "03:00", "03:30", "04:00"]
    let y1 = new Date().getFullYear()
    let d1 = new Date().toString().split(" ")[2]
    let m1 = new Date().getMonth() + 1
    m1 = m1.toString()
    m1 = m1.length === 2 ? m1 : "0" + m1
    let [ob, setob] = useState({ name: "", email: "", phone: "", query: "", dt: `${y1}-${m1}-${d1}`, slot: slots[0] })
    const classes = useStyles();
    React.useEffect(function () {
        // slots=slots.filter(x=>a.some(y=>y.slot===x)===false)
        const url = `${Config.API}appointment/get`;
        apiCall.get(url)
            .then(d => d.data)
            .then(d => {
                // slots=slots.filter(x=>d.some(y=>y.slot===x)===false)
                const bookSlots = d ? d : [];
                seta(bookSlots)
                setob({ ...ob, slot: slots[0] })
                console.log(d, "get")
            })
    }, [])
    const insert = e => {
        e.preventDefault()
        const url = `${Config.API}appointment/create`;
        apiCall.post(url, ob)
            .then(d => console.log(d.data))
    }
    const getFreeOrNot = (dt, x) => {
        return (a.some(item => item.date === dt && item.slot === x))
    }

    return <>

        <Modal {...props} show={show1} >
            <Modal.Header closeButton>
                <Modal.Title>Please fill the Appointment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={insert}>
                    <div className="form-group">
                        <input value={ob.name} onChange={e => setob({ ...ob, name: e.target.value })} type="text" className="form-control" id="textmessage1_std" placeholder="Name *"
                            required />
                    </div>
                    <div className="form-group">
                        <input value={ob.phone} onChange={e => setob({ ...ob, phone: e.target.value })} type="tel" className="form-control" pattern="^\d{10}$" id="textmessage4_std" placeholder="Mobile *"
                            required />
                    </div>
                    <div className="form-group">
                        <input value={ob.email} onChange={e => setob({ ...ob, email: e.target.value })} type="email" className="form-control" id="textmessage3_std" placeholder="Email *" required
                        />
                    </div>
                    <div className="form-group">
                        <textarea value={ob.query} onChange={e => setob({ ...ob, query: e.target.value })} type="text" className="form-control" id="textmessage4_std" placeholder="Enter your query *"
                            required />
                    </div>
                    <div>
                        {/* <label>Pick Date&time</label><br /> */}
                        {/* <DatePicker selected={startdate}  onChange={date=>setdate(date)} />
                                <TimePicker onChange={time=>setonchange(time)} value={time} format="h:m a" disableClock="false" /> */}
                        <input type="date" min={ob.dt} value={ob.dt} onChange={e => setob({ ...ob, dt: e.target.value })} />
                    </div><br />


                    <div className="form-group" >
                        <p>all availbale slots {slots.length}</p>
                        {slots.map((x,index) => (
                            getFreeOrNot(ob.dt, x)
                                ?
                                <button key={index} className="btn bg-danger" onClick={e => alert('already booked')}>{x}</button>
                                :
                                <button key={index} onClick={e => setob({ ...ob, slot: x })} className="btn btn-primary" type="button" >{x}</button>
                        ))}
                    </div>



                    <div >
                        <button type="submit" className="btn btn-primary">Submit</button>
                    </div>



                </form>
            </Modal.Body>
        </Modal>
    </>

}


