import React from "react";
import "./Footer.css";
import logo from "./assets/images/LogoBlack.png";

import fb from "./assets/images/fb.svg";
import insta from "./assets/images/insta.svg";
import youtube from "./assets/images/youtube.svg";
import twitter from "./assets/images/twitter.svg";

const Footer = () => {
  return (
    <>
      <div className="main-footer">
        <div className="left-footer">
          <img src={logo} alt="" />
          <p>
            School Address : Sr.No.111, Ghule Wasti,
            Near New Serum Institute Plant, Manjri, Pune- 412307.
            Website : www.pioneerpublicschool.in
            Email : admin@pioneerpublicschool.net 
          </p>

          <div className="social-links">
            <h3>Follow Us On:</h3>
            <div className="icons">
              <div
                className="social-icon"
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFF",
                }}
              >
                <img src={twitter} alt="" />{" "}
              </div>
              <div
                className="social-icon"
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFF",
                }}
              >
                <img src={fb} alt="" />{" "}
              </div>
              <div
                className="social-icon"
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFF",
                }}
              >
                <img src={insta} alt="" />{" "}
              </div>
              <div
                className="social-icon"
                style={{
                  height: "40px",
                  width: "40px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#FFF",
                }}
              >
               <a href="https://www.youtube.com/channel/UCVs-TIVZc1HZa3WPl5vPkiA" target="_blank"> <img src={youtube} alt="" />{" "}</a>
              </div>
            </div>
          </div>
        </div>

        <div className="right-footer">
          <div></div>
          <div className="right-list-footer">
            <h5>Know Us</h5>

            <ul>
             <a href="#about"> <li>About Us</li></a>
             <a href="#gallery"><li>Gallery</li></a> 
             <a href="#contact"><li>Contact Us</li></a> 
              <li>Announcements</li>
              <li>LC Details</li>
              <li>Career</li>
            </ul>
          </div>
          <div className="right-list-footer">
            <h5>Admissions</h5>

            <ul>
              <li>Primary</li>
              <li>Pre-Primary</li>
              <li>Secondary</li>
              <li>Announcements</li>
              <li>Pre-Secondary</li>
            </ul>
          </div>
          <div className="right-list-footer">
            <h5>Our Schools</h5>

            <ul>
              <li>Pune</li>
              <li>Mumbai</li>
              <li>Hyderabad</li>
              <li>Nanded</li>
              <li>Parbhani</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="last-footer">
        <div className="for-enquiry">
          <h4>For More Enquiry:</h4>
          <input type="text" placeholder="Enter Email" />
          <button>Submit</button>
        </div>

        <div className="quick-links">
          <h4>Quick Links:</h4>
          <ul>
            <li>Terms & Conditions</li>
            <li>Privacy Policy</li>
            <li>Disclaimer</li>
          </ul>
        </div>
      </div>
      <br />
      <br />
      <br />
      <hr />
      <p className="copy">Copyright @2023 -PIONEER PUBLIC SCHOOL</p>
    </>
  );
};

export default Footer;
