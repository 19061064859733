import React, { Component } from 'react';
import './announcment.css';

import Config from '../config'
import APICall from '../apiCall/api';

class Announcment extends Component{
    constructor(props) {
        super(props);
        this.state = {
            announcmentList: []
        }
        this.apiCall = new APICall();
    }

    componentDidMount() {
        console.log('component mounted')
        this.getAnnouncment();
    }

    render() {
        return(
            <section  className="page_padding" id="announcment">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="section-heading text-center">Announcment</h3>
                            <hr className="page_hr" />
                        </div>
                    </div>
                   {this.getAnnouncmentForm()}
                </div>
            
            </section>
        );
    }

    getAnnouncmentForm(){
        return this.state.announcmentList.map((announ) => {
            return(
                <div className="row" key={announ._id}>
                <div className="col-lg-12">
                    <p className="alert">
                        <i className="far fa-check-circle fa-2x alert__icon"></i>
                        {announ.text}
                    </p>
                </div>
            </div>
            );
        });
    }

    getAnnouncment(){
        console.log('announcement fetch fn')
        const url = `${Config.API}announcment/get`;
        this.apiCall.get(url).then((data) => {
            const announcmentList = data;
            this.setState({ announcmentList });
        }).catch((err) => {
            console.log(err,'error announcement')
        });
    }
}

export default Announcment;