import React, { Component } from 'react';
import './home.css';
class Home extends Component {
    render() {
        return (
            <section className="page_padding" id="home">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-lg-12">
                            <marquee style={{color:'red'}} className="main-ticker highlighted_color" behavior="scroll" direction="left">
                                <p className="details"><strong>Admission open for 2021-2022 for std.1-std.11th <br/> Mid Term Admission open for 2020-2021 for std.11th (Science/ Commerce/ Arts)</strong></p>
                            </marquee>
                            <h3 className="section-heading text-center">WELCOME TO PIONEER PUBLIC SCHOOL</h3>
                            <hr className="page_hr" />
                            <p className="mb-4 section_paragraph">Pioneer Public School is a most trusted name in quality education with CBSE curriculum. It offers schooling from
                            Nursery to XII The school focuses on holistic development of young minds We believe in providing an environment
                            that is conducive for young learners to explore and experience the joy of learning in a play way method. This prestigious temple of education was established in 2010 in a building located in Hadapsar and Manjri, PUNE.
                            </p>
                            <p>
                                PIONEER PUBLIC SCHOOL is synonymous with quality education and is a pioneer in mooting all-round development and to foster an all-round growth in its wards.
                                <a className="highlighted_color" href="#about"> Read More</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Home;