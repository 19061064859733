import React, { Component } from 'react';
import image1 from './img/1.jpg';
import image2 from './img/2.jpg';

import './carousal.css';
// https://medium.com/@colinrlly/send-store-and-show-images-with-react-express-and-mongodb-592bc38a9ed
class Carousal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images: [
                { key: 1, value: image2, className: 'carousel-item active' }, 
                { key: 2, value: image1, className: 'carousel-item' }
            ]
        }
    }
    render() {
        return (
                <div className="container-fluid carousel_section page_line">
                    <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">
                            {this.getCarousalItems()}
                        </div>
                    </div>
                </div>
            

        );
    }

    getCarousalItems() {
        return this.state.images.map((image) => (
            <div className={image.className} key={image.key}>
            <img src={image.value} className="d-block w-100" alt="carousalImage" />
            <div className="carousel-caption d-md-block carousel-caption_custom">
            <a href="admission_procedure.docx" className="btn btn-primary btn-sm custom_on_image_btn">Admission Procedure</a>
            <a href= "admission_form.docx" className="btn btn-primary btn-sm custom_on_image_btn">Admission Form</a>
            </div>
            </div>)
        );

    }
}

export default Carousal;