import React, { Component } from 'react';
import { toast } from 'react-toastify';

import Config from '../config'
import APICall from '../apiCall/api';

class Teacher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teacherList: [],
            classList: [],
            classTeacherCheck: false
        }
        this.create = this.create.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleClassTeacher = this.toggleClassTeacher.bind(this);
        this.apiCall = new APICall();
    }

    create(e) {
        e.preventDefault();
        if(this.state.classTeacherCheck && this._selectedOption.value.length === 0){
            toast.error('please select class');
            return;
        }
        const options = this.makeCreateTeacher(
            this._firstName.value.trim(),
            this._middleName.value,
            this._lastName.value.trim(),
            this._phone.value.trim(),
            this._email.value.trim()
        );

        const url = `${Config.API}users/sign-up`;
        this.apiCall.post(url, options).then((data) => {
            if(data.data === 'email exist'){
                toast.info(data.data);
            } else {
                this.assignTeacherRole(data.data);
                this.getAllTeachers();
                console.log('userUpdated', data);
            }
            
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
    }

    delete(id) {
        const url = `${Config.API}users/delete/${id}`;
        this.apiCall.delete(url).then(() => {
            this.getAllTeachers();
            toast.info('Success: Deleted!');
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
    }

    getAllTeachers() {
        const url = `${Config.API}users/getUsersById/3`;
        this.apiCall.get(url).then((res) => {
            const teacherList = res.data;
            this.setState({ teacherList });
        }).catch((err) => {
            console.log(err)
        });
    }

    componentDidMount() {
        this.getAllTeachers();
        this.getClass();
    }

    getClass() {
        const url = `${Config.API}class/get`;
        this.apiCall.get(url).then((data) => {
            const classList = data;
            this.setState({ classList });
        }).catch((err) => {
            console.log(err)
        });
    }

    classItem() {
        return this.state.classList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    toggleClassTeacher(e) {
        const toggleValue = this.state.classTeacherCheck;
        this.setState({ classTeacherCheck: !toggleValue });
    }

    render() {
        return (
            <div className="container page_padding">
                <div id="teacherId">
                    <div className="card">
                        <div className="card-header" id="teacherHeading">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseTeacher" aria-expanded="true" aria-controls="collapseTeacher">
                                    Teacher
                            </button>
                            </h5>
                        </div>

                        <div id="collapseTeacher" className="collapse hide" aria-labelledby="teacherHeading" data-parent="#teacherId">
                            <div className="card-body custom-card-body">
                                <form onSubmit={this.create}>
                                    <div className="form-group form-check">
                                        <input type="checkbox" id="class_teacher_check" className="form-check-input" checked={this.state.classTeacherCheck} onChange={(e) => this.toggleClassTeacher(e)} />
                                        <label className="form-check-label" htmlFor="class_teacher_check">select me to create class teacher</label>
                                    </div>
                                    <div className="form-group" hidden={!this.state.classTeacherCheck}>
                                        <select className="custom-select"
                                            ref={(v) => this._selectedOption = v}>
                                            <option value=''>Select Class</option>
                                            {this.classItem()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="_firstName" placeholder="Enter First Name" ref={(v) => this._firstName = v} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="_middleName" placeholder="Enter Middle Name" ref={(v) => this._middleName = v} />
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="_lastName" placeholder="Enter Last Name" ref={(v) => this._lastName = v} required/>
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" id="_email" placeholder="Enter Email" ref={(v) => this._email = v} required/>
                                    </div>
                                    <div className="form-group">
                                        <input type="tel" className="form-control" pattern="^\d{10}$" id="_phone" placeholder="Enter 10 digit Phone" ref={(v) => this._phone = v} required/>
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </form>
                                <section className="row">
                                    {this.renderTeacherList()}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderTeacherList() {
        return this.state.teacherList.map((item) => {
            return (
                <div className="alert alert-info alert-dismissible fade show col-3 page_left_margin" role="alert" key={item.id}>
                    {`${item.firstName} ${item.middleName ? item.middleName : ''} ${item.lastName}`}
                    <button className="close" aria-label="Close" onClick={() => this.delete(item.id)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            )
        });
    }

    makeCreateTeacher(firstName, newMiddleName, lastName, phone, email) {
        const middleName = newMiddleName ? newMiddleName.trim() : '';
        return {
            firstName,
            middleName,
            lastName,
            phone,
            email
        }
    }

    assignTeacherRole(user) {
        const url = `${Config.API}users/assignRoles`;
        const selectedClass = this.state.classList.find(cls => cls._id === this._selectedOption.value);
        const options = {
            id: user._id,
            roles: [
                {
                    roleId: 3,
                    roleName: "Teacher",
                    assignClass: selectedClass
                }
            ]
        }
        
        this.apiCall.post(url, options).then((data) => {
            this.clearForm();
            toast.info('Success: Created!');
        }).catch((err) => {
            console.log(err)
        });
    }

    clearForm() {
        this._selectedOption.value = "";
        this._firstName.value = "";
        this._middleName.value = "";
        this._lastName.value = "";
        this._email.value = "";
        this._phone.value = "";
        this._email.value = ""
        this.setState({classTeacherCheck: false});
    }
}

export default Teacher;