import React, { Component } from 'react';

import Menu from './menu';
import MenuItem from './menuItem';
import MenuButton from './menuButton';
import './menuBar.css';


class MenuBar extends Component {
    constructor(props){
      super(props);
      this.state={
        menuOpen:false,
      }
    }
    
    handleMenuClick() {
      this.setState({menuOpen:!this.state.menuOpen});
    }
    
    handleLinkClick() {
      this.setState({menuOpen: false});
    }
    
    render(){
      const roles = JSON.parse(sessionStorage.getItem('roles'));
      let menu = [];
      if(roles.find(r => r.roleName === "Accountent" || r.roleName === "Teacher" || r.roleName === "Admin")){
        menu.push('StudentForm');
        menu.push('StudentByClass');
      }
      if(roles.find(r => (r.roleName === "Teacher" && r.assignClass) || r.roleName === "Admin")){
        menu.push('Attendence');
        menu.push('Homework');
      }

      if(roles.find(r => r.roleName === "Admin")){
        menu.push('FeeReport');
        menu.push('Session');
        menu.push('Class');
        menu.push('Subject');
        menu.push('Accountent');
        menu.push('Teacher');
        menu.push('PendingApprovalForDiscount');
        menu.push('Gallary');
        menu.push('UploadGallery');
        menu.push('Announcement');
        menu.push('Query');
        menu.push('Enquiry');
        menu.push('ScheduleAnnouncmentByClassOrStudent');
        menu.push('Appointment')
        menu.push('Visitor')
        
      }
      const menuItems = menu.map((val,index)=>{
        return (
          <MenuItem 
            key={index}
            value = {val} 
            delay={`${index * 0.1}s`}
            onClick={()=>{this.handleLinkClick();}}>{val}</MenuItem>)
      });
      
      return(
        <div className = 'col-1'>
          <div className='menuBar-container'>
            <MenuButton open={this.state.menuOpen} onClick={()=>this.handleMenuClick()} color='white'/>
          </div>
          <Menu open={this.state.menuOpen}>
            {menuItems}
          </Menu>
        </div>
      )
    }
  }

export default MenuBar;