import React, { Component } from 'react';
import history from '../../history';
import data from './header.data.json';
import logo from './logo.png';
import schoolName from './school_name.png';
import './header.css';

import Config from '../../config'
import APICall from '../../apiCall/api';
import MenuBar from './../menuBar/menuBar'

class Header extends Component {
    constructor(props) {
        super(props);
        this.logOut = this.logOut.bind(this);
        this.apiCall = new APICall();
    }
    render() {
        const userName = sessionStorage.getItem('userName');
        // const roles = sessionStorage.getItem('roles');
        const id = sessionStorage.getItem('id');
        return (
            <nav className="navbar navbar-expand-lg navbar-light custom-header container-fluid">
                <article className="row">
                    <MenuBar />
                    <div className="col-8">
                        <a className="navbar-brand custom-brand" href="#page-top">
                            <img className="svg d-inline-block align-top" src={logo} alt="school logo" />
                            <img className="school_name" src={schoolName} alt="school Name" />
                        </a>
                    </div>
                    <div className="col-1">
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                        aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="admin-navbar navbar-toggler-icon"></span>
                    </button>
                    </div>
                </article>
                <div className="collapse navbar-collapse admin-navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto admin-navbar">
                            {userName ? this.getUserHeader(userName, id) : this.getItemList()}
                        </ul>
                    </div>
            </nav>
        )
    }

    getItemList() {
        return data.map((item) => (<li className="nav-item" key={item.key}>
            <a className="nav-link js-scroll-trigger" href={item.href}>{item.name}</a>
        </li>))
    }

    getUserHeader(userName, id) {
        return <React.Fragment>
            <li className="nav-item" key="100">
                <label className="btn btn-link">{userName} |</label>
            </li>
            <li className="nav-item" key="101">
                <a className="btn btn-link"
                    href="/change-password"
                > change password | </a>
            </li>
            <li className="nav-item" key="102">
                <button className="btn btn-link"
                    onClick={(e) => { this.logOut(id); }}
                > logout </button>
            </li>
        </React.Fragment>
    }

    logOut(id) {
        const url = `${Config.API}users/sign-out`;
        const option = { id };
        console.log('option', option);
        this.apiCall.post(url, option).then(() => {
            sessionStorage.removeItem('userName');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('id');
            sessionStorage.removeItem('roles');
            // history.push('/login')
            window.location.href = '/login';
        }).catch((err) => {
            console.log(err)
        });
    }
}

export default Header;
