import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import Config from '../config'
import APICall from '../apiCall/api';

import './gallery.css';

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: [],
            imageGalleryList: [] 
        };
        this.apiCall = new APICall();
    }

    componentDidMount(){
        this.getImageGallery();
    }

    getImageGallery() {
        const url = `${Config.API}galleryImage/get`;
        this.apiCall.get(url).then((data) => {
            const imageGalleryList = data;
            this.setState({ imageGalleryList, isOpen: Array(imageGalleryList.lenght).fill(false) });
        }).catch((err) => {
            console.log(err)
        });
    }

    render() {
        return (
            <section className="page_padding" id="gallery">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="section-heading text-center">Gallery</h3>
                            <hr className="page_hr" />
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {this.createGallery()}
                    </div>
                </div>
            </section>
        );
    }

    createGallery() {
        const imageHtml = [];
        const { photoIndex, isOpen } = this.state;
        const imagePath = `${Config.API}img/`;
        let count = 0;
        for (let imageGallery of this.state.imageGalleryList) {
            const localCount = count;
            imageHtml.push(<div className="col-lg-4 col-sm-6" key={localCount}>
                <button type="button" className="btn" onClick={(e) => {this.updateOpen(localCount); }}>
                    <figure className="figure">
                        <img src={`${imagePath}${imageGallery.galleryImage[0].imageName}`} className="custom_thumbnail figure-img img-fluid rounded" alt="" />
                        <figcaption className="figure-caption text-center"> <h5> {imageGallery.galleryName} </h5></figcaption>
                    </figure>
                </button>
                {isOpen[localCount] && (
                    <Lightbox
                        mainSrc={`${imagePath}${imageGallery.galleryImage[photoIndex].imageName}`}
                        nextSrc={`${imagePath}${imageGallery.galleryImage[(photoIndex + 1) % imageGallery.galleryImage.length].imageName}`}
                        prevSrc={`${imagePath}${imageGallery.galleryImage[(photoIndex + imageGallery.galleryImage.length - 1) % imageGallery.galleryImage.length].imageName}`}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + imageGallery.galleryImage.length - 1) % imageGallery.galleryImage.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % imageGallery.galleryImage.length,
                            })
                        }
                    />
                )}

            </div>);
            count++;
        }
        return imageHtml;
    }

    updateOpen(count){
       let isOpenCopy = Array(this.state.imageGalleryList.length).fill(false);
       isOpenCopy[count] = true;
       this.setState({ isOpen: isOpenCopy }) 
    }
}

export default Gallery;