import React, { Component } from 'react';
import { toast } from 'react-toastify';

import Config from '../config'
import APICall from '../apiCall/api';
import { mongoObjectId } from './common';

class StudentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            classList: [],
            categoryList: [],
            sessionList: [],
            isDepositeGreaterThanTotalFee: false,
            image: []
        }
        this.create = this.create.bind(this);
        this.onDepositeChange = this.onDepositeChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.apiCall = new APICall();
    }

    create(e) {
        e.preventDefault();
        const formData = this.makeStudentOption(
            this._studentName.value,
            this._parentName.value,
            this._parentEmail.value,
            this._parentPhone.value,
            this._totalFee.value,
            this._depositeFee.value,
            this._selectedOption.value,
            this._selectedCategory.value,
            this._selectedSession.value,
            this.state.image,
            this._rollNo.value
        );
        const url = `${Config.API}student/create`;

        this.apiCall.post(url, formData).then((data) => {
            this.clearFormData();
            toast.info('Success:Student created!');
        }).catch((err) => {
            toast.error('Error:Failed!');
        });

    }
    onDepositeChange() {

        const checkCondition = (parseInt(this._depositeFee.value) > parseInt(this._totalFee.value));
        this.setState({ isDepositeGreaterThanTotalFee: checkCondition });
    }

    componentDidMount() {
        this.getclass();
        this.getCategory();
        this.getSession();
    }

    handleChange(event) {
        this.setState({ image: event.target.files });
    }

    render() {
        return (
            <div className="container page_padding">
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id="collapseEight">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                    Student Form
                            </button>
                            </h5>
                        </div>

                        <div id="collapseSeven" className="collapse show" aria-labelledby="collapseEight" data-parent="#accordion">
                            <div className="card-body custom-card-body">
                                <form  encType="multipart/form-data">
                                    <div className="form-group">
                                        <select className="custom-select"
                                            ref={(v) => this._selectedSession = v} required>
                                            {this.sessionItem()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select className="custom-select"
                                            ref={(v) => this._selectedOption = v} required>
                                            <option value=''>Select Class</option>
                                            {this.classItem()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <select className="custom-select"
                                            ref={(v) => this._selectedCategory = v} required>
                                            <option value=''>Select Student's Fee Category</option>
                                            {this.categoryItem()}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="textmessage1_std" placeholder="Enter Student Name"
                                            ref={(v) => this._studentName = v} required />
                                    </div>

                                    <div className="form-group">
                                        <input type="text" className="form-control" id="textmessage2_std" placeholder="Enter Parent Name"
                                            ref={(v) => this._parentName = v} required />
                                    </div>
                                    <div className="form-group">
                                        <input type="email" className="form-control" id="textmessage3_std" placeholder="Enter Parent Email"
                                            ref={(v) => this._parentEmail = v} />
                                    </div>
                                    <div className="form-group">
                                        <input type="tel" className="form-control" pattern="^\d{10}$" id="textmessage4_std" placeholder="Enter Parent Phone of 10 digit"
                                            ref={(v) => this._parentPhone = v} required />
                                    </div>
                                    <div className="form-group" hidden={true}>
                                        <input type="number" className="form-control" id="textmessage5_std" placeholder="Total Fee"
                                            ref={(v) => this._totalFee = v} />
                                    </div>
                                    <div className="form-group" hidden={true}>
                                        <input type="number" className="form-control" id="textmessage6_std" placeholder="Deposite Fee"
                                            ref={(v) => this._depositeFee = v} onChange={this.onDepositeChange} />
                                        {this.state.isDepositeGreaterThanTotalFee &&
                                            <label style={{ color: "#db3e00" }}>Deposite Fee should be less than Total Fee</label>}

                                    </div>
                                    <div className="form-group">
                                        <input type="file" accept="image/*" multiple onChange={this.handleChange} 
                                        ref={v=> this._fileInput = v} />
                                    </div>
                                    <div className="form-group">
                                        <input type="number" className="form-control" id="rollNo" placeholder="Roll No"
                                            ref={(v) => this._rollNo = v} />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-primary" onClick={this.create}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    getclass() {
        const url = `${Config.API}class/get`;
        this.apiCall.get(url).then((data) => {
            const classList = data;
            this.setState({ classList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getCategory() {
        const url = `${Config.API}feecategory/get`;
        this.apiCall.get(url).then((data) => {
            const categoryList = data;
            this.setState({ categoryList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getSession() {
        const url = `${Config.API}session/get`;
        this.apiCall.get(url).then((data) => {
            const sessionList = data;
            this.setState({ sessionList });
        }).catch((err) => {
            console.log(err)
        });
    }

    classItem() {
        return this.state.classList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    categoryItem() {
        return this.state.categoryList.map((item) => {
            return (
                <option value={item.text} key={item._id}>{item.text}</option>
            )
        });
    }

    sessionItem() {
        return this.state.sessionList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    makeStudentOption(studentName,
        parentName, parentEmail, parentPhone,
        totalFee, depositeFee, selectedClass,
        selectedCategory, selectedSession,
        image, rollNo) {
        
        const className = this.state.classList.find(cls => cls._id === selectedClass);
        const session = this.state.sessionList.find(cls => cls._id === selectedSession);

        const student = {
            name: studentName,
            imageName: image[0] ? image[0].name : "",
            rollNo,
            feeCategory: selectedCategory,
            parent: {
                email: parentEmail,
                phone: parentPhone,
                name: parentName
            },
            fee: {
                totalFee,
                depositeFee,
                paymentMode: 'Not defined yet'
            },
            homeWorkStatus: [],
            Attendance: [],
            _id: mongoObjectId(),
            createdDate: new Date()
        }
        const formData = new FormData();
        formData.append('uploader', this.state.image[0])
        formData.append('class', JSON.stringify(className));
        formData.append('session', JSON.stringify(session));
        formData.append('student', JSON.stringify(student));
        return formData;
    }

    clearFormData() {
        this._studentName.value = "";
        this._parentName.value = "";
        this._parentEmail.value = "";
        this._parentPhone.value = "";
        this._totalFee.value = "";
        this._depositeFee.value = "";
        this._selectedOption.value = "";
        this._selectedCategory.value = "";
        this.setState({image:[]});
        this._rollNo.value = "";
        this._fileInput.value = "";
    }
}

export default StudentForm;