import React, { Component } from 'react'
import Config from '../config'
import APICall from '../apiCall/api';

export default class Appointment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            aptmList: []
        }
        this.apiCall = new APICall();
    }
   componentDidMount(){
    const url = `${Config.API}appointment/get`;
    this.apiCall.get(url)
      .then(res=>{
      this.setState({aptmList:res})
       })
   }
    render() {
        return (
            <div className="container page_padding">
                <div id="enquiryid">
                    <div className="card">
                        <div className="card-header" id="enquiryheading">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseenquiry" aria-expanded="true" aria-controls="collapseenquiry">
                                    Appointments
                            </button>
                            </h5>
                        </div>

                        <div id="collapseenquiry" className="collapse hide" aria-labelledby="enquiryheading" data-parent="#enquiryid">
                            <div className="card-body custom-card-body">
                                {/* {this.AppointmentItem()} */}
                                {this.state.aptmList.map((item,index)=>(
                                    // <p>{item.name}-{item.email}-{item.phone}-{item.query}-{item.date}</p>
                                    

                                    <section className="row" key={index}>
                                    <div className="alert alert-info alert-dismissible fade show col-10 page_left_margin" role="alert" key={item._id}>
                                        Name:{item.name} MobileNo:{item.phone} Query: {item.query} Date:{item.date} Email:{item.email} Slot:{item.slot}
                                    </div>

                                    
                                </section>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
