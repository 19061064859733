import React, { useState } from "react";
import "./header.css";
import schoolLogo from "./assets/images/Logo.svg";

import close from "./assets/close.png";
import open from './assets/open.jpg';

import { Redirect } from "react-router-dom";

const HeaderNew = () => {
  const [navVisibilty,setNavVisibilty] = useState(false);
  const [expandMob,setExpandMob]= useState(false);
  const [navigate,setNavigate] = useState(false);

  const Handlevisitor = (e)=>{
    console.log('handleVisitor')
    // this.props.history.push('/visitor')
    setNavigate(true)
  }
  
  if(navigate){
    return <Redirect to="/visitor" />
  }

  const handleMouseOver = (e)=>{
    console.log(e.target)
    let isOtherLinksParent = e.target.classList.contains('otherLinksParent');
    let isOthersLink = e.target.closest('.otherLinksUl');

    if(isOtherLinksParent || isOthersLink){
      
      let dropDownNav = document.querySelector('.otherLinksUl');
      if(dropDownNav){
        dropDownNav.classList.add('active')
      }

      setNavVisibilty(true)
    }else{
      let dropDownNav = document.querySelector('.otherLinksUl');
      if(dropDownNav){
        dropDownNav.classList.remove('active')
      }

      setNavVisibilty(false)
    }
  }
const handleMouseLeave = (e)=>{
  console.log(e)
  setNavVisibilty(false)
}



const handleNavExpand = ()=>{
  const mobNav = document.querySelector('.nav-list-container');
  if(mobNav){
    mobNav.classList.toggle('active')
    setExpandMob(prev => !prev)
  }
}
  return (
    <header className="main-header">
        <span className="navExpand"> <img src={expandMob ? close : open} className="navIcon" onClick={handleNavExpand}/> </span>
      <div className="logo">
        <img src={schoolLogo} alt="School Logo" />
      </div>
      <nav className="nav-list-container" onMouseOver={handleMouseOver}>
        <ul className="nav-list-items">
        <a href="#"> <li>HOME</li> </a>
        <a href="#about"> <li>ABOUT US</li> </a>
        <a href="#gallery"> <li>GALLERY</li> </a>
        <a href="#contact"> <li>CONTACT US</li> </a>
        <a href="#announcement"> <li>ANNOUNCEMENT</li> </a>
        <a href="#announcement"> <li>CURRICULUM </li> </a> 
        
        <a href="#"> <li class="otherLinksParent" >OTHER LINKS </li>
        <ul className={ navVisibilty ? "otherLinksUl active" : "otherLinksUl"}>
          <li><a href="teachers_details.docx">Teachers Details</a></li>
          <li><a href="students_details.docx">Students Details</a></li>
          <li><a href="lc_details.docx">LC Details</a></li>
          <li><a href="e-prospectus.pdf">E-Prospectus</a></li>
          <li><a href="career.jpg">Career</a></li>
          <li ><a onClick={Handlevisitor}>visitor</a></li>
        </ul>
        </a> 
        </ul>
      </nav>
    </header>
  );
};

export default HeaderNew;
