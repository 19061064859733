import React, { Component } from 'react';

class Services extends Component{
    render() {
        return(
            <section  className="page_padding" id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="section-heading text-center">At Your Service</h3>
                            <hr className="page_hr" />
                        </div>
                    </div>
                </div>
                
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="service-box mt-5 mx-auto">
                                <i className="fas fa-3x fa-gem icon_color mb-3 sr-icon-1"></i>
                                <h5 className="mb-3">Curriculum and Pedagogy</h5>
                                <p className="text-muted mb-0">The school curriculum is ingeniously designed to enhance creativity, collaboration, communication skills and
                                team ethics.</p>
                            </div>
                        </div>
                        
                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="service-box mt-5 mx-auto">
                                <i className="fas fa-3x fa-paper-plane icon_color mb-3 sr-icon-2"></i>
                                <h5 className="mb-3">Project Based Learning Programme</h5>
                                <p className="text-muted mb-0">The transaction of the curriculum is designed with a ‘child-centric’ pedagogy i.e. giving primacy to children’s
                                experiences and their voices.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 text-center">
                            <div className="service-box mt-5 mx-auto">
                                <i className="fas fa-3x fa-code icon_color mb-3 sr-icon-3"></i>
                                <h5 className="mb-3">Early Childhood Years</h5>
                                <p className="text-muted mb-0">learning curve of children and their abilities to grasp new and advanced concepts get enhanced through use of
                                activities like dramatic play, art, and social games.</p>
                            </div>
                        </div>
                    </div>    
                </div>
            </section>
        );
    }
}

export default Services;