import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { toast } from 'react-toastify';
import Config from '../config'
import APICall from '../apiCall/api';
import logo from '../header/logo.png';
import { Table } from 'react-bootstrap';


class StudentByClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessionList: [],
            classList: [],
            categoryList: [],
            currentSessionId: {},
            students: [],
            selectedClass: '',
            roles: JSON.parse(sessionStorage.getItem('roles')),
            selectedStudent:null,
            isDepositGreaterThanTotalFee: false,
            isDiscountGreaterThanTotalFee: false,
            totalFee: 0.0,
            remainingFee: 0.0,
            isDiscountApproved: false,
            depositFeeDate: '',
            isDepositDate: false,
            paymentMod: '',
            depositAllFee: 0.0,
            selectedStudentsDepositHistory: {}
        }
        this.onClassChangeGetStudent = this.onClassChangeGetStudent.bind(this);
        this.update = this.update.bind(this);
        this.onDepositChange = this.onDepositChange.bind(this);
        this.onDiscountChange = this.onDiscountChange.bind(this);
        this.setTotalFee = this.setTotalFee.bind(this);
        this.apiCall = new APICall();
        this.onDepositDateFocusLost = this.onDepositDateFocusLost.bind(this);
    }

    onDepositDateFocusLost() {
        if (this._depositDate.value) {
            this.setState({isDepositDate: true})
        }
    }
    
    onDepositChange() {

        const checkCondition = (parseInt(this._depositFee.value) > parseInt(this.state.totalFee));
        this.setState({ isDepositGreaterThanTotalFee: checkCondition });

        // const remainingFee = (parseFloat(this.state.totalFee) - parseFloat(this._depositFee.value)).toFixed(2)
        const depositFee = this._depositFee && this._depositFee.value ? parseFloat(this._depositFee.value) : parseFloat(0);
        const discount = this._discount  && this._discount.value ? parseFloat(this._discount.value) : parseFloat(0);
        const remainingFee = (parseFloat(this.state.totalFee) - (this.state.depositAllFee + discount + depositFee)).toFixed(2);

        this.setState({ remainingFee: remainingFee });

        // if (checkCondition) {
        //     this._depositFee.value = "0";
        // }
    }
    onDiscountChange() {

        const checkDiscountCondition = (parseInt(this._discount.value) > parseInt(this.state.totalFee));
        this.setState({ isDiscountGreaterThanTotalFee: checkDiscountCondition });

        const depositFee = this._depositFee && this._depositFee.value ? parseFloat(this._depositFee.value) : parseFloat(0);
        const discount = this._discount  && this._discount.value ? parseFloat(this._discount.value) : parseFloat(0);
        
        const remainingFee = (parseFloat(this.state.totalFee) - (this.state.depositAllFee + discount + depositFee)).toFixed(2);

        this.setState({ remainingFee: remainingFee });

        if (checkDiscountCondition) {
            this._discount.value = "0";
        }
    }

    setTotalFee() {
        let totalFee = 0.0;
        try {
            const registrationFee = this._registrationFee ? parseFloat(this._registrationFee.value) : parseFloat(0);
            const admissionFees = this._admissionFees ? parseFloat(this._admissionFees.value) : parseFloat(0);
            const termFee = this._termFee ? parseFloat(this._termFee.value) : parseFloat(0);
            const tuitionFees = this._tuitionFees ? parseFloat(this._tuitionFees.value) : parseFloat(0);
            const transportFees = this._transportFees ? parseFloat(this._transportFees.value) : parseFloat(0);
            const previousDuesFee = this._previousDuesFee ? parseFloat(this._previousDuesFee.value) : parseFloat(0);
            const higherClassFees = this._higherClassFees ? parseFloat(this._higherClassFees.value) : parseFloat(0);
            const middleClassFees = this._middleClassFees ? parseFloat(this._middleClassFees.value) : parseFloat(0);

            totalFee = (
                  registrationFee 
                + admissionFees
                + termFee
                + tuitionFees
                + transportFees
                + previousDuesFee
                + higherClassFees
                + middleClassFees
            ).toFixed(2)
                
        } catch (error) {
            console.log('Error while calculating total fees: ', error)
        }
        this.setState({ totalFee: totalFee});

        let remainingFee = 0.0;
        try {
            const depositFee = this._depositFee && this._depositFee.value ? parseFloat(this._depositFee.value) : parseFloat(0);
            const discount = this._discount && this._discount.value ? parseFloat(this._discount.value) : parseFloat(0);
            remainingFee = totalFee - (depositFee + discount)
        } catch (error) {
            console.log('Error while calculating remaining fee', error)
        }
        this.setState({ remainingFee: remainingFee });

    }


    componentDidMount() {
        this.getclass();
        this.getCategory();
        this.getSession()
    }

    onClassChangeGetStudent(event) {    
        if (this._selectedClass) {
            this.setState({ selectedClass: this._selectedClass.value });
            this.setState({ currentSessionId: this._selectedSession.value });
            this.getStudentByClass(this._selectedClass.value, this._selectedSession.value);
        }
    }

    getStudentByClass(classId, currentSessionId) {
        if (classId !== '') {
            const url = `${Config.API}student/getByClass`;
            this.apiCall.post(url, { currentSessionId, classId }).then((data) => {
                const students = this.getStudentList(data.students);
                this.setState({ students });
            }).catch((err) => {
                console.log(err)
            });
        } else {
            this.setState({ students: [] });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(parseInt(prevState.depositAllFee) !== parseInt(this.state.depositAllFee)) {
            this._depositFee.value = this.state.depositAllFee;
        }
      }

      calculateTotalDeposit(depositFee) {
        let totalDepositFee = 0.0;
        if (! depositFee){
            return
        }
        Object.entries(depositFee)
        .forEach( ([key, value]) => {
            if (key && value.value) {
                totalDepositFee += parseFloat(`${value.value}`);
            }
         })
        return totalDepositFee
    }

      getSelectedStudent(studentId){
        this.setTotalFee();
        this.setState({ selectedStudent: null });
        const url = `${Config.API}student/getById`;
        const currentSessionId =  this.state.currentSessionId;
        const classId = this.state.selectedClass;
        this.apiCall.post(url, { currentSessionId, classId, studentId }).then((data) => {
            this.setState({ selectedStudent: data });
            this.setState({ totalFee: data.fee.totalFee ? data.fee.totalFee : 0.0 });
            this.setState({ remainingFee: data.fee.remainingFee ? data.fee.remainingFee : 0.0 });
            this.setState({ isDiscountApproved: data.fee.isDiscountApproved ? data.fee.isDiscountApproved : false });
            this.setState({ selectedStudentsDepositHistory: data.fee.depositFee})
            this.setState({ depositAllFee: this.calculateTotalDeposit(data.fee.depositFee)})
    
        }).catch((err) => {
            console.log(err)
        })
    }

    render() {
        const url = `${Config.API}student/downloaddExcelForStudentsDetails`;
        return <div className="container page_padding">
            <a type="button" href={url} target="_blank" rel="noopener noreferrer" download>
                <button className="btn btn-outline-primary btn-sm mb-3">Export Students</button>
            </a>
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingFour">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                Student By Class
                            </button>
                        </h5>
                    </div>

                    <div id="collapseFour" className="collapse show" aria-labelledby="headingFour" data-parent="#accordion">
                        <div className="card-body custom-card-body">
                            <form>
                                    <div className="form-group">
                                        <select className="custom-select"
                                            ref={(v) => this._selectedSession = v} required onChange={this.onClassChangeGetStudent}>
                                            <option value=''>Select Session</option>
                                            {this.sessionItem()}
                                        </select>
                                    </div>

                                <div className="form-group">
                                    <select className="custom-select"
                                        ref={(v) => this._selectedClass = v} required onChange={this.onClassChangeGetStudent}>
                                        <option value=''>Select Class</option>
                                        {this.classItem()}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <ul className="list-group">
                                        {this.studentList()}
                                    </ul>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="studentModal" tabIndex="-1" aria-labelledby="studentModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.showSelectedStudent()}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="studentFeeModal" tabIndex="-1" aria-labelledby="studentModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <a target='_blank'><button className="btn btn-success print-invoice" onClick={() => this.printForm('printInvice')}>Print</button></a>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.showSelectedStudentFee()}
                        </div>

                        <div className="modal-footer">
                            <a target='_blank'><button className="btn btn-success print-invoice" onClick={() => this.printForm('printInvice')}>Print</button></a>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span className="btn btn-danger">Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    classItem() {
        return this.state.classList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    categoryItem() {
        return this.state.categoryList.map((item) => {
            return (
                <option value={item.text} key={item._id}>{item.text}</option>
            )
        });
    }

    studentList() {
        const list = this.state.students.map((item, index) => {
            return (
                <li className="list-group-item list-group-item-info" key={index}>
                    <div className="form-check row">
                        <label className="form-check-label col-10" htmlFor={item.id}>
                            {`${index + 1}. ${item.name}`}
                        </label>
                        <label className="form-check-label col-2">
                            <span className="icon-padding" data-toggle="modal" data-target="#studentModal" onClick={()=>{this.getSelectedStudent(item.id)}}><i className="far fa-edit"></i></span>
                            <span className="icon-padding" data-toggle="modal" data-target="#studentFeeModal" onClick={()=>{this.getSelectedStudent(item.id)}}><i className="fa fa-print"></i></span>
                        </label>
                    </div>
                </li>
            )
        });

        return list;
    }

    getclass() {
        const url = `${Config.API}class/get`;
        this.apiCall.get(url).then((data) => {
            const assignClassIds = [];
            this.state.roles.forEach(r => {
                if (r.assignClass) {
                    assignClassIds.push(r.assignClass._id);
                }
            });
            const classList = assignClassIds.length > 0 ? data.filter(d => assignClassIds.includes(d._id)) : data;

            this.setState({ classList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getCategory() {
        const url = `${Config.API}feecategory/get`;
        this.apiCall.get(url).then((data) => {
            const categoryList = data;
            this.setState({ categoryList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getSession() {
        const url = `${Config.API}session/get`;
        this.apiCall.get(url).then((data) => {
            const sessionList = data;
            this.setState({ sessionList });
        }).catch((err) => {
            console.log(err)
        });
    }

    sessionItem() {
        return this.state.sessionList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    getStudentList(data) {
        return data.map(d => ({ id: d._id, name: d.name, present: true }))
    }
    
    showAllDiscount(date_key, data) {
        return (
        <div className='border mt-2'>
            <p>Deposite Date: {date_key}</p>
            <p>Deposite Fee: {data.value}</p>
            <p>Transaction Id: {data.transaction_id}</p>
            <p>Payment Mod: {data.payment_mod}</p>
        </div>
    )}

    showSelectedStudent(){
        const {selectedStudent} = this.state;
        const student = selectedStudent;

        return student ? 
            <form id="selectedStudent"  encType="multipart/form-data">
                <Row>
                    <Col>    
                        <div className="form-group">
                            <label htmlFor="textmessage1_std">Enter Student Name</label>
                            <input type="text" className="form-control" id="textmessage1_std" 
                            placeholder="Enter Student Name" defaultValue={student.name} 
                            ref={(v) => this._studentName = v}
                            required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="_feeCategory">Student's Fee Category</label>
                            <select className="custom-select" id="_feeCategory"
                                ref={(v) => this._feeCategory = v} defaultValue={student.feeCategory} required>
                                <option value=''>Select Student's Fee Category</option>
                                {this.categoryItem()}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="textmessage2_std">Enter Parent Name</label>
                            <input type="text" className="form-control" id="textmessage2_std" defaultValue={student.parent.name}
                                ref={(v) => this._parentName = v} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="textmessage3_std">Enter Parent Email</label>
                            <input type="email" className="form-control" id="textmessage3_std" defaultValue={student.parent.email}
                                ref={(v) => this._parentEmail = v} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="textmessage4_std">Enter Parent Phone of 10 digit</label>
                            <input type="tel" className="form-control" pattern="^\d{10}$" 
                            id="textmessage4_std" defaultValue={student.parent.phone}
                                ref={(v) => this._parentPhone = v} required />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="rollNo">Roll No</label>
                            <input type="number" className="form-control" id="rollNo" defaultValue={student.rollNo}
                                ref={(v) => this._rollNo = v} />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" disabled={!this.state.isDepositDate} onClick={this.update} data-dismiss="modal">Submit</button>
                        </div>

                    </Col>

                    {this.showFeeForm(student)}

                </Row>                
            </form> : ""
    }
    
    getCurrentDateInput = () => {
        const dateObj = new Date();
        // get the month in this format of 04, the same for months
        const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
        const day = ("0" + dateObj.getDate()).slice(-2);
        const year = dateObj.getFullYear();
        const shortDate = `${year}-${month}-${day}`;
        return shortDate;
      };

    showSelectedStudentFee(){
        const {selectedStudent} = this.state;
        const student = selectedStudent;
        return student ? 
            <form id="printInvice" encType="multipart/form-data">
                    {this.showFeeFormInvoice(student)}
            </form> : ""

    }
    
    printForm(formName) {
        var headerContents = document.head.innerHTML;
        var printContents = document.getElementById(formName).innerHTML;
        let w = window.open();
        w.document.write('<html><head><title>Pioneer Print Invoice</title>');
        w.document.write(headerContents);
        w.document.write('</head><body>');
        console.log(printContents);
        w.document.write(printContents);
        w.document.write('<script type="text/javascript">addEventListener("load", () => { print(); close(); })</script></body></html>');
        w.document.close();
        w.focus();
    }

    getRandomId = (min = 0, max = 500000) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        const num =  Math.floor(Math.random() * (max - min + 1)) + min;
        return num.toString().padStart(6, "0")
    };

  
    showFeeForm(student) {
        const rolesName = this.state.roles.map((role)=>{return role.roleName});
        const className = this.state.classList.find(cls => cls._id === this.state.selectedClass).text;

        return rolesName.includes("Admin") || rolesName.includes("Accountent") ?
            <Col>
            <Card bg="secondary" text="white" id='fees'>
                <Card.Header>Student's Fees Details</Card.Header>
                <Card.Body>
                    { 
                    this._feeCategory?.value !== 'PPS-EIP' ?
                        <>
                            <div className="form-group">
                                <label htmlFor="_registrationFee">Enter Registration Fee</label>
                                <input type="number" className="form-control" id="_registrationFee" min="0" step="0.5" defaultValue={student.fee?.registrationFee || 0} ref={(v) => this._registrationFee = v} onChange={this.setTotalFee} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="_admissionFees">Enter Admission Fee</label>
                                <input type="number" className="form-control" id="_admissionFees" min="0" step="0.5" defaultValue={student.fee?.admissionFees || 0} ref={(v) => this._admissionFees = v} onChange={this.setTotalFee} />
                            </div>
                            <div className="form-group">
                            <label htmlFor="_termFee">Enter Term Fee</label>
                                <input type="number" className="form-control" id="_termFee" min="0" step="0.5" defaultValue={student.fee?.termFee || 0} ref={(v) => this._termFee = v} onChange={this.setTotalFee} />
                            </div>
                        </>
                    : ""
                    }

                    <div className="form-group">
                            <label htmlFor="_tuitionFees">Enter Tuition Fee</label>
                            <input type="number" className="form-control" id="_tuitionFees" min="0" step="0.5" defaultValue={student.fee?.tuitionFees || 0} ref={(v) => this._tuitionFees = v} onChange={this.setTotalFee} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="_transportFees">Enter Transport Fee</label>
                        <input type="number" className="form-control" id="_transportFees" min="0" step="0.5" defaultValue={student.fee?.transportFees || 0} ref={(v) => this._transportFees = v} onChange={this.setTotalFee} />
                    </div>
                    
                    {
                        this._feeCategory?.value == 'PPS' ?
                            <div className="form-group">
                                <label htmlFor="_previousDuesFee">Previous Dues Fee</label>
                                <input type="number" className="form-control" id="_previousDuesFee" min="0" step="0.5" defaultValue={student.fee?.previousDuesFee || 0} ref={(v) => this._previousDuesFee = v} onChange={this.setTotalFee} />
                            </div>
                        : ""
                    }


                    {/* {
                        
                        ["Nursery A", "LKG A", "UKG A"].includes(className) ?
                        ""
                            : ["9th A", "10th A"].includes(className) ?
                                    <div className="form-group">
                                        <label htmlFor="_higherClassFees">Enter Higher Class Fee</label>
                                        <input type="number" className="form-control" id="_higherClassFees" min="0" step="0.5" defaultValue={student.fee?.higherClassFees || 0} ref={(v) => this._higherClassFees = v} onChange={this.setTotalFee} />
                                    </div>
                                :
                                    <div className="form-group">
                                        <label htmlFor="_middleClassFees">Enter Middle Class Fee</label>
                                        <input type="number" className="form-control" id="_middleClassFees" min="0" step="0.5" defaultValue={student.fee?.middleClassFees || 0} ref={(v) => this._middleClassFees = v} onChange={this.setTotalFee} />
                                    </div>
                    } */}

                    <div className="form-group">
                        <label htmlFor="_discount">Enter Discount</label>
                        <input type="number" className="form-control" id="_discount" min="0" step="0.5"
                            ref={(v) => this._discount = v} defaultValue={student.fee?.discount || 0} onChange={this.onDiscountChange} readOnly={student.fee?.isDiscountApproved ? true : false}/>
                        {this.state.isDiscountGreaterThanTotalFee && <label style={{ color: "#db3e00" }}>Discount should be less than Total Fee</label>}
                    </div>

                    <div className="form-group border p-2">
                        <div className="form-group">
                            <label htmlFor="_depositFee">Enter Deposit Fee</label>
                            <input type="number" className="form-control" id="_depositFee" min="0" step="0.5"
                                ref={(v) => this._depositFee = v} defaultValue={this.state.depositAllFee} onChange={this.onDepositChange} />
                        </div>
                            
                        <div className="form-group">
                            <label htmlFor="_depositDate">Enter Deposit Date</label>
                            <input type="date" className="form-control" id="_depositDate" defaultValue={this.getCurrentDateInput()}
                            ref={(v) => this._depositDate = v} onBlur={this.onDepositDateFocusLost} onChange={(event) => this.setState({depositFeeDate: event.target.value})} />
                            
                        </div>
                            
                        <div className="form-group">
                                <label htmlFor="_transactionId">Enter Transaction ID</label>
                            <input type="text" className="form-control" id="_transactionId"
                            ref={(v) => this._transactionId = v} required/>
                        </div>
                            
                        <div className="form-group border p-2">    
                            <label htmlFor="_paymentMod">Payment Mod</label>
                            {/* <input type="date" className="form-control" id="_paymentMod"
                            ref={(v) => this._paymentMod = v} /> */}
                            <div onChange={(event) => this.setState({paymentMod: event.target.value})}>
                                <div className='form-check'>
                                    <input className='form-check-input' type="radio" value="Cash" name="gender" id='inlineRadio1' checked/>
                                    <label className="form-check-label" htmlFor="inlineRadio1">Cash</label>
                                </div>

                                <div className='form-check'>
                                    <input className='form-check-input' type="radio" value="Bank Transfer" name="gender" id='inlineRadio2'/>
                                    <label className="form-check-label" htmlFor="inlineRadio2">Bank Transfer</label>
                                </div>

                                <div className='form-check'>
                                    <input className='form-check-input'type="radio" value="UPI" name="gender" id='inlineRadio3' />
                                    <label className="form-check-label" htmlFor="inlineRadio3">UPI</label>
                                </div>

                                <div className='form-check'>
                                    <input className='form-check-input'type="radio" value="Check" name="gender" id='inlineRadio4' />
                                    <label className="form-check-label" htmlFor="inlineRadio4">Check</label>
                                </div>
                            </div>

                        </div>
                            
                        <div className="form-group">
        
                            <div className='border mt-2 p-2'><label>History</label>
                            {
                            student.fee?.depositFee ? Object.keys(student.fee?.depositFee).map((key) => {
                                let depositFee = student.fee.depositFee[key];
                                return <div key={key}>{this.showAllDiscount(key, depositFee)}</div>
                            }) : ""
                            }
                            </div>

                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="_remainingFee">Remaining Fee</label>
                        <input type="number" className="form-control" id="_remainingFee" placeholder="Remaining Fee" value={this.state.remainingFee} readOnly={true}/>
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="_isDis">Is discount</label>
                        <input type="text" className="form-control" id="_isDis" placeholder="Discount" value={this.state.isDiscountApproved} readOnly={true}/>
                    </div> */}

                    <div className="form-group">
                        <label htmlFor="_totalFee">Total Fee Paid</label>
                        <input type="number" className="form-control" id="_totalFee" placeholder="Total Fee" value={this.state.totalFee} readOnly={true}/>
                    </div>
                    

                </Card.Body>
            </Card>
            </Col>

        : ""
    }

    showFeeFormInvoice(student) {
        const rolesName = this.state.roles.map((role)=>{return role.roleName});
        const className = this.state.classList.find(cls => cls._id === this.state.selectedClass).text;
        const sessionName = this.state.sessionList.find(cls => cls._id === this.state.currentSessionId).text;
        let counter = 1;

        return rolesName.includes("Admin") || rolesName.includes("Accountent") ?
            <div>
                <div className='d-flex justify-content-end'>
                    <div className='text-center fee-receipt'>FEE RECEIPT</div>
                </div>
                <div className='d-flex justify-content-end'>
                    <div className='text-center bellow-fee-receipt'>Subject to PUNE Jursdiction only</div>
                </div>

                <Row>
                    <Col lg="3">
                    <img className="rounded-circle img-fluid" width={150} height={150} src={logo} alt="school logo" />
                        {/* <img src="https://image.ibb.co/dDW27U/Work_Section2_freelance_img1.png" class="rounded-circle img-fluid" alt=""/> */}
                    </Col>
                    <Col>
                        <h2 style={{'color':'#1061b6'}}>PIONEER PUBLIC SCHOOL</h2>
                        <p style={{'color':'#1061b6'}}>School Address: Sr.No. 111, Ghule Wasti, Near Serun Institute Plant, <br/> Manjri, Pune 412307.<br/>
                        www.pioneerpublicschool.in | pioneerpublicschool_2010@yahoo.com</p>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='d-flex justify-content-start fee-receipt-box'>
                            <div className='p-3'><b>Student Name: </b>{student?.name}<br/>
                            <b>Class: </b>{className}<br/>
                            <b>Father's Name: </b>{student?.parent?.name}</div>
                        </div>
                    </Col>
                    <Col>
                    <div className='d-flex fee-receipt-box'>
                        <div className='p-3'><b>Academic Year: </b>{sessionName}<br/><br/>
                        <b>Receipt No: </b><div className='rectangle-box-1 ml-1'>{this.getRandomId()}</div><b>Date: </b><div className='rectangle-box-1 ml-1'>{new Date().toJSON().slice(0, 10)}</div></div>
                    </div>
                     </Col>
                </Row>
                
                <Table striped bordered hover className='mt-3'>
                <thead>
                    <tr>
                        <th>SR NO</th>
                        <th>PARTICULARS</th>
                        <th>AMOUNT</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                    (this._feeCategory?.value !== 'PPS-EIP' && (
                        <>
                        <tr>
                            <td>{counter}</td><td>Registration Fee</td><td>{student.fee?.registrationFee || 0}</td>
                        </tr>
                        <tr>
                            <td>{counter = counter + 1}</td><td>Admission Fee</td><td>{student.fee?.admissionFees || 0}</td>
                        </tr>
                        <tr>
                            <td>{counter = counter + 1}</td><td>Term Fee</td><td>{student.fee?.termFee || 0}</td>
                        </tr>
                        </>))
                    }

                        <tr>
                            <td>{counter === 1 ? counter : counter = counter + 1}</td><td>Tuition Fee</td><td>{student.fee?.tuitionFees || 0}</td>
                        </tr>
                        <tr>
                            <td>{counter = counter + 1}</td><td>Transport Fee</td><td>{student.fee?.transportFees || 0}</td>
                        </tr>
                    
                    {
                        (this._feeCategory?.value === 'PPS' && (
                        <tr>
                            <td>{counter = counter + 1}</td><td>Previous Dues Fee</td><td>{student.fee?.previousDuesFee || 0}</td>
                        </tr>
                        ))
                    }

                        <tr>
                            <td>{counter = counter + 1}</td><td>Discount</td><td>{student.fee?.discount || 0}</td>
                        </tr>
                        <tr>
                            <td>{counter = counter + 1}</td><td>Deposit Fee</td><td>{
                                student.fee?.depositFee ? Object.keys(student.fee?.depositFee).map((key) => {
                                    let depositFee = student.fee.depositFee[key];
                                    return <div key={key}>{this.showAllDiscount(key, depositFee)}</div>
                                }) : ""
                                }</td>
                        </tr>

                        <tr>
                            <td>{counter = counter + 1}</td><td>Remaining Fee</td><td>{this.state.remainingFee}</td>
                        </tr>
                        <tr>
                            <td>{counter = counter + 1}</td><td>Total Fee Paid</td><td>{this.state.totalFee}</td>
                        </tr>
                
                </tbody>
             </Table>

            </div>

        : ""
    }
    
    update(e) {
        e.preventDefault();

        const formData = this.makeStudentOption();
        const url = `${Config.API}student/updateById`;

        this.apiCall.post(url, formData).then((data) => {
            this.clearFormData();
            toast.info('Success:Student updated!');
        }).catch((err) => {
            console.log(err)
            toast.error('Error: Failed to update!', {err});
        });

    }

    makeStudentOption() {
        
        const newStudentsDepositHistory = {
            [this.state.depositFeeDate]: {
                "value": this._depositFee.value ? this._depositFee.value : 0,
                "payment_mod": this.state.paymentMod,
                "transaction_id": this._transactionId.value
            }
        };
        Object.entries(this.state.selectedStudentsDepositHistory).forEach(([key, val]) => {
            newStudentsDepositHistory[key] = val
        });
        console.log('newStudentsDepositHistory: ', newStudentsDepositHistory)

        this.setState({
            selectedStudentsDepositHistory: newStudentsDepositHistory
        });

        const student = {
            name: this._studentName.value,
            rollNo: this._rollNo.value,
            feeCategory: this._feeCategory.value,
            parent: {
                email: this._parentEmail.value,
                phone: this._parentPhone.value,
                name: this._parentName.value
            },
            fee: {
                registrationFee: this._registrationFee ? this._registrationFee.value : 0.0,
                admissionFees: this._admissionFees ? this._admissionFees.value : 0.0,
                termFee: this._termFee ? this._termFee.value : 0.0,
                tuitionFees: this._tuitionFees ? this._tuitionFees.value : 0.0,
                transportFees: this._transportFees ? this._transportFees.value : 0.0,
                previousDuesFee: this._previousDuesFee ? this._previousDuesFee.value : 0.0,
                higherClassFees: this._higherClassFees ? this._higherClassFees.value : 0.0,
                middleClassFees: this._middleClassFees ? this._middleClassFees.value : 0.0,
                totalFee: this.state.totalFee,
                remainingFee: this.state.remainingFee,
                depositFee: newStudentsDepositHistory, //this._depositFee.value
                discount: this._discount.value,
                isDiscountApproved: this.state.isDiscountApproved,
                paymentMode: 'Not defined yet'
            },
            _id: this.state.selectedStudent._id,
            updatedDate: new Date(),
            updateBy: sessionStorage.getItem('userName')
        }
        const formData = new FormData();
        formData.append('classId', this.state.selectedClass);
        formData.append('sessionId', this.state.currentSessionId);
        formData.append('updatedStudent', JSON.stringify(student));
        return formData;
    }

    clearFormData() {
        this._studentName.value = "";
        this._feeCategory.value = "";
        this._parentName.value = "";
        this._parentEmail.value = "";
        this._parentPhone.value = "";

        if (this._registrationFee) {
            this._registrationFee.value = this._registrationFee ? this._registrationFee.value : 0.0;
        }
        if (this._admissionFees) {
            this._admissionFees.value = this._admissionFees ? this._admissionFees.value : 0.0;
        }
        if (this._termFee) {
            this._termFee.value = this._termFee ? this._termFee.value : 0.0;
        }
        if (this._tuitionFees) {
            this._tuitionFees.value = this._tuitionFees ? this._tuitionFees.value : 0.0;
        }
        if (this._transportFees) {
            this._transportFees.value = this._transportFees ? this._transportFees.value : 0.0;
        }
        if (this._previousDuesFee) {
            this._previousDuesFee.value = this._previousDuesFee ? this._previousDuesFee.value : 0.0;
        }
        if (this._higherClassFees) {
            this._higherClassFees.value = this._higherClassFees ? this._higherClassFees.value : 0.0;
        }
        if (this._middleClassFees) {
            this._middleClassFees.value = this._middleClassFees ? this._middleClassFees.value : 0.0;
        }
        this._depositFee.value = "";
        this._discount.value = "";

        this._rollNo.value = "";
        this.setState({totalFee: 0.0});
        this.setState({remainingFee: 0.0});
        this.setState({isDepositDate: false})
    }
}


export default StudentByClass;