import React, { Component } from 'react';
import Config from '../config'
import APICall from '../apiCall/api';
import history from '../history';

import './login.css';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            validationErrors:[]
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.apiCall = new APICall();
    }

    validateForm() {
        return this.state.email.length > 0 && this.state.password.length > 0;
    }

    handleChange(event) {
        this.setState({
            [event.target.id]: event.target.value
        }
        );
    }

    handleSubmit(e) {
        e.preventDefault();
        const url = `${Config.API}users/login`;
        const options = {
            email: this.state.email,
            password: this.state.password
        }
        this.apiCall.post(url, options).then((res) => {
        console.log('options', res)
            if (res.data.match) {
                sessionStorage.setItem('userName', res.data.payload.userName);
                sessionStorage.setItem('id', res.data.payload.id);
                sessionStorage.setItem('roles', JSON.stringify(res.data.payload.roles));
                sessionStorage.setItem('token', res.data.token);
                sessionStorage.setItem('isValidToken', true);
                // history.push('/dashboard')
                window.location.href = '/dashboard';
            } else{
                this.setState({validationErrors:['Invalid UserName or Passoword!']});
            }
        }).catch((err) => {
            console.log(err);
        });

    }

    render() {
        return (
            <section className="container login-container">
                <article className="row justify-content-md-center">
                    <article className="col-md-6 login-form-1">
                        <h3>Login</h3>
                        <form onSubmit={this.handleSubmit}>
                            {this.state.validationErrors.map((err,index) => (
                                <div className="form-group" key={index}>
                                    <label className="col-sm col-form-label text-danger">{err}</label>
                                </div>
                            ))}

                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Your Email *" value={this.state.email}
                                    id="email" onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Your Password *" value={this.state.password}
                                    id="password" onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <input type="submit" className="btnSubmit" value="Login"
                                    disabled={!this.validateForm()} />
                            </div>
                        </form>
                    </article>
                </article>
            </section>
        );
    }
}

export default Login;