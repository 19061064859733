import React from "react";
import HomeImageSlider from "./HomeImageSlider";
import "./MainHome.css";
import ReactPlayer from "react-player";
import ImageGrid from "./ImageGrid";
import AnnouncementNew from "./AnnouncementNew";
import Announcment from '../announcment/announcment'

//Stats Logo
import schoolLogo from "./assets/images/school 1.svg";
import studentsLogo from "./assets/images/students.svg";
import teachersLogo from "./assets/images/teacher.svg";
import landscapeLogo from "./assets/images/landscape.svg";

//Founders Images
import founder1 from "./assets/images/founder1.png";
import founder2 from "./assets/images/founder2.png";
import founder3 from "./assets/images/founder3.png";
import playSVG from "./assets/images/play.svg";

//Services Logo
import servicesLogo1 from "./assets/images/diamond 1.svg";
import servicesLogo2 from "./assets/images/paper-plane 1.svg";
import servicesLogo3 from "./assets/images/code 1.svg";

//Gallery grid image
import galleryImage from "./assets/images/gallery.svg";

//Branches Images
import branch1 from "./assets/images/branch1.svg";
import branch2 from "./assets/images/branch2.svg";
import branch3 from "./assets/images/branch3.svg";
import branch4 from "./assets/images/branch4.svg";
import rightArrow from "./assets/images/arrow-right.svg"; 

import manjariSchool from './assets/images/manjariSchool.png'
import kolwadiSchool from './assets/images/kolwadiSchool.png'
import mahaDevNagarSchool from './assets/images/mahadevNagarSchool.png'
import keshavNagarSchool from './assets/images/KeshavNagarSchool.png'


import missionImg from './assets/images/mission.jpg'
import visionImg from './assets/images/vision.jpg'
import valueImg from './assets/images/value.jpg'

import Contact from "./Contact";
import Testinomials from "./Testinomials";
import Footer from "./Footer";

import PrinpleImg from './assets/images/principle.jpg'


const watchVideoBtnStyle = {
  backgroundColor: "#558e6f",
    border: "none",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    gridGap: "10px",
    gap: "10px",
    padding: "8px 24px",
    color: "#ffffff",
    textTransform: "uppercase",
    fontFamily: "Montserrat-bold",
    fontSize: "12px",
    fontWeight: "600",
    cursor: "pointer"
}

const MainHome = () => {
  return (
    <div>
      <HomeImageSlider />

      <div className="welcome-container" id="about">
        <h3 className="about-school">About School</h3>
        <h1 className="school-name">WELCOME TO PIONEER PUBLIC SCHOOL</h1>
        <p className="school-text">
          Pioneer Public School is a most trusted name in quality education with
          CBSE curriculum. It offers schooling from Nursery to XII The school
          focuses on holistic development of young minds We believe in providing
          an environment that is conducive for young learners to explore and
          experience the joy of learning in a play way method. This prestigious
          temple of education was established in 2010 in a building located in
          Hadapsar and Manjri, PUNE.
        </p>
        <p className="last-text">
          PIONEER PUBLIC SCHOOL is synonymous with quality education and is a
          pioneer in mooting all-round development and to foster an all-round
          growth in its wards.
        </p>
        <button>Read More</button>
      </div>

      <div className="stats-container">
        <div className="stats">
          <div className="stats-logo stats-logo1">
            <img src={schoolLogo} alt="" />
          </div>
          <div className="stats-number">
            <p>
              <span className="number">4+</span> <br /> Schools
            </p>
          </div>
        </div>
        <div className="stats">
          <div className="stats-logo stats-logo2">
            <img src={studentsLogo} alt="" />
          </div>
          <div className="stats-number">
            <p>
              <span className="number">1000+</span> <br /> Students
            </p>
          </div>
        </div>
        <div className="stats">
          <div className="stats-logo  stats-logo3">
            <img src={teachersLogo} alt="" />
          </div>
          <div className="stats-number">
            <p>
              <span className="number">100+</span> <br /> Teachers
            </p>
          </div>
        </div>
        <div className="stats">
          <div className="stats-logo  stats-logo4">
            <img src={landscapeLogo} alt="" />
          </div>
          <div className="stats-number">
            <p>
              <span className="number"> 80,000</span> <br /> Sq. ft. Area
            </p>
          </div>
        </div>
      </div>

      <div className="school-founder-container">
        <div className="container container1">
          <div className="content">
            <h1>Mr. Gyanesh Sharma (Founder) Message</h1>
            <p>
              One of the best CBSE schools in Pune Schooling from nursery to XII
              60,000 Sq Ft Play Ground Sports for All Compulsory Spacious class
              rooms Safe Secure and lush green environment.
            </p>
            <a  href="https://youtu.be/QxZv-5s9Txc">
            <button className="play-video" style={watchVideoBtnStyle}>
              <img src={playSVG} alt="" /> Watch Video
            </button>
            </a> 
          </div>
          <div className="video">
          <ReactPlayer url={`https://youtu.be/QxZv-5s9Txc`}
            className="react-player" controls />
          </div>
        </div>
        <div className="container container2">
          <div className="image">
          <ReactPlayer url={`https://youtu.be/Znezu8H5U68`}
          className="react-player" controls />

          </div>
          <div className="content">
            <h1>Mrs. Nidhika Gyanesh Sharma (Director) Message</h1>
            <p>
              Our staff is working towards a clear sense of our goals and
              commitment to our school and the educational opportunity we will
              provide. We are striving for ALL kids to learn and grow. It is
              important to me, as your principal, that everyone who steps
              through our doors are excited to be here and be a part of
              something new! Working together enables us all to meet the
              challenges and needs in a positive, fun and nurturing environment.
              Please call our school when you have questions or concerns. We
              wish your child a happy and successful school year and look
              forward to this exciting journey with you all.
            </p>
            <a href="https://youtu.be/Znezu8H5U68" target="_blank">
            <button className="play-video" style={watchVideoBtnStyle}>
              <img src={playSVG} alt="" />Watch Video
            </button>
            </a> 
          </div>
        </div>
      </div>


      <div className="branches">
        <h3 className="main-heading">MISSION VISION AND VALUE</h3>
        <h1 className="secondary-heading" style={{ textAlign: "center" }}>
          Our MISSION VISION AND VALUE
        </h1>

        <div
          className="branches-list"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            margin: "-50 100px",
          }}
        >
          <div className="branch-list-item">
            <div className="branch-image school1">
              <img src={missionImg} alt="" />
            </div>
          </div>
          <div className="branch-list-item">
            <div className="branch-image">
              <img src={visionImg} alt="" />
            </div>
          </div>
          <div className="branch-list-item">
            <div className="branch-image">
              <img src={valueImg} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="services">
        <h3 className="main-heading">Services</h3>
        <h1 className="secondary-heading">At Your Service</h1>

        <div className="services-list">
          <div className="list-items">
            <div className="logo logo1">
              <img src={servicesLogo1} alt="" />
            </div>
            <div className="list-content">
              <h3>Curriculum and Pedagogy</h3>
              <p>
                The school curriculum is ingeniously designed to enhance
                creativity, collaboration, communication skills and team ethics.
              </p>
            </div>
          </div>
          <div className="list-items">
            <div className="logo logo2">
              <img src={servicesLogo2} alt="" />
            </div>
            <div className="list-content">
              <h3>Project Based Learning Programme</h3>
              <p>
                The transaction of the curriculum is designed with a
                ‘child-centric’ pedagogy i.e. giving primacy to children’s
                experiences and their voices.
              </p>
            </div>
          </div>
          <div className="list-items">
            <div className="logo logo3">
              <img src={servicesLogo3} alt="" />
            </div>
            <div className="list-content">
              <h3>Early Childhood Years</h3>
              <p>
                learning curve of children and their abilities to grasp new and
                advanced concepts get enhanced through use of activities like
                dramatic play, art, and social games.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery" id="gallery">
        <h3 className="main-heading">Gallery</h3>
        <h1 className="secondary-heading" style={{ textAlign: "center" }}>
          Our Memories
        </h1>

        {/* For now only */}
        {/* <div className="main-gallery-grid">
          <img src={galleryImage} alt="" />
        </div> */}
        <ImageGrid />

      </div>

      <div className="branches">
        <h3 className="main-heading">Branches</h3>
        <h1 className="secondary-heading" style={{ textAlign: "center" }}>
          Our Branches
        </h1>

        <div
          className="branches-list"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            margin: "-50 100px",
          }}
        >
          <div className="branch-list-item">
            <div className="branch-image school1">
              <img src={manjariSchool} alt="" />
            </div>
          </div>
          <div className="branch-list-item">
            <div className="branch-image">
              <img src={kolwadiSchool} alt="" />
            </div>
          </div>
          <div className="branch-list-item">
            <div className="branch-image">
              <img src={mahaDevNagarSchool} alt="" />
            </div>
          </div>
          <div className="branch-list-item">
            <div className="branch-image">
              <img src={keshavNagarSchool} alt="" />
            </div>
          </div>
        </div>
      </div>

      <Contact />

      {/* <Announcment /> */}
      <AnnouncementNew />
      <Testinomials />

      <Footer/>
    </div>
  );
};

export default MainHome;
