import React from 'react';

const GoogleMapEmbed = () => {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  return (
    <div className="mapContainer">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.3888772443765!2d73.95559497413464!3d18.511320869458853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c2133f025e7f%3A0x554ddd875607cff4!2sPioneer%20Public%20School!5e0!3m2!1sen!2sin!4v1689446716100!5m2!1sen!2sin"
        width={isMobile ? '380': '600'}
        height={isMobile ? '400': '450'}
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
} 

export default GoogleMapEmbed;
