import React, { useState } from "react";
import Config from "../config";
import APICall from "../apiCall/api";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import { notification } from "antd";
import './resp-form.css'
export default function VisitorForm() {
  let [ob, setob] = useState({
    checked: "",
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    meetperson: "",
    purpose: "",
    createdate: "",
    hearus: "",
    whoru: "",
    language: "",
    feedback: "",
    intime: "",
    outtime: "",
    status: "no",
  });
  let apiCall = new APICall();

  const submitAll = (e) => {
    e.preventDefault();
    const url = `${Config.API}visitor/create`;
    apiCall
      .post(url, ob)
      .then((d) => {
        notification.open({ message: "Your Request is submitted successfully! We will getback to you Soon" });
      })

      .catch((d) => {
        notification.open({ message: "something went wrong" });
      });
    setob({
      checked: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      meetperson: "",
      purpose: "",
      createdate: "",
      hearus: "",
      whoru: "",
      language: "",
      feedback: "",
      intime: "",
      outtime: "",
      status: "no",
    });
  };

  return (
    <div className="container">
      <div className="my-form-design1">
        <h1 className="text-center">Welcome To Pioneer Public School</h1>
        <div className="">
          <p>Please Fill The Required Information *</p>
        </div>

        <form className="res-form" onSubmit={submitAll}>
          <div class="form-row">
            <div className="form-group col-md-6">
              <input
                value={ob.firstname}
                onChange={(e) => setob({ ...ob, firstname: e.target.value })}
                type="text"
                className="form-control"
                id="textmessage1_std"
                placeholder="First Name*"
                required
              />
            </div>
            <div className="form-group col-md-6">
              <input
                value={ob.lastname}
                onChange={(e) => setob({ ...ob, lastname: e.target.value })}
                type="text"
                className="form-control"
                id="textmessage1_std"
                placeholder="Last Name*"
                required
              />
            </div>
          </div>

          <div class="form-group">
            {/* <div class="col-10"> */}
            <input
              value={ob.createdate}
              onChange={(e) => setob({ ...ob, createdate: e.target.value })}
              class="form-control"
              type="date"
            />
            {/* </div> */}
          </div>
          <div class="form-row">
            <div className="form-group col-md-6">
              <label>In Time</label>
              <input
                value={ob.intime}
                onChange={(e) => setob({ ...ob, intime: e.target.value })}
                class="form-control"
                type="time"
              />
            </div>
            <div className="form-group col-md-6">
              <label>Out Time</label>
              <input
                value={ob.outtime}
                onChange={(e) => setob({ ...ob, outtime: e.target.value })}
                class="form-control"
                type="time"
              />
            </div>
          </div>

          <div className="form-group">
            <input
              value={ob.mobile}
              onChange={(e) => setob({ ...ob, mobile: e.target.value })}
              type="tel"
              className="form-control"
              pattern="^\d{10}$"
              id="textmessage4_std"
              placeholder="Your Mobile Number*"
              required
            />
          </div>
          <div className="form-group">
            <input
              value={ob.email}
              onChange={(e) => setob({ ...ob, email: e.target.value })}
              className="form-control"
              placeholder="Your Email *"
              required
            />
          </div>
          <div className="form-group">
            <select
              value={ob.meetperson}
              onChange={(e) => setob({ ...ob, meetperson: e.target.value })}
              className="form-control"
            >
              <option>Meeting Person?</option>
              <option value="Gyanesh Sharma">Gyanesh Sharma</option>
              <option value="Nidhi">Nidhi</option>
              <option value="Nitin Gaikwad">Nitin Gaikwad</option>
            </select>
          </div>
          <div className="form-group">
            <select
              value={ob.purpose}
              onChange={(e) => setob({ ...ob, purpose: e.target.value })}
              className="form-control"
            >
              <option>Purpose Of Visit</option>
              <option value="admission">Admission</option>
              <option value="marketing">Marketing</option>
              <option value="other">Others</option>
            </select>
          </div>
          <div className="form-group">
            <select
              value={ob.hearus}
              onChange={(e) => setob({ ...ob, hearus: e.target.value })}
              className="form-control"
            >
              <option value="">How Did You Hear About Us?</option>
              <option value="social media">Social Media</option>
              <option value="friends">Friends</option>
              <option value="advertisement">Advertisement</option>
              <option value="other">Others</option>
            </select>
          </div>

          {/* <div class="form-check form-check-inline"> */}
          <div className="form-group">
            Who you are ? <br />
            <label>
              Parents{" "}
              <input
                onClick={(e) => setob({ ...ob, whoru: "Parents" })}
                value="Parents"
                checked={ob.whoru === "Parents"}
                type="checkbox"
              />
            </label>
            <label>
              Relatives{" "}
              <input
                onClick={(e) => setob({ ...ob, whoru: "Relatives" })}
                value="Relatives"
                checked={ob.whoru === "Relatives"}
                type="checkbox"
              />
            </label>
            <label>
              Friends{" "}
              <input
                onClick={(e) => setob({ ...ob, whoru: "Friends" })}
                value="Friends"
                checked={ob.whoru === "Friends"}
                type="checkbox"
              />
            </label>
            <label>
              Social Media{" "}
              <input
                onClick={(e) => setob({ ...ob, whoru: "SocialMedia" })}
                value="SocialMedia"
                checked={ob.whoru === "SocialMedia"}
                type="checkbox"
              />
            </label>
            <label>
              Others{" "}
              <input
                onClick={(e) => setob({ ...ob, whoru: "Others" })}
                value="Others"
                checked={ob.whoru === "Others"}
                type="checkbox"
              />
            </label>
          </div>
          {/* </div> */}

          <div className="form-group">
            Preferred Languages: <br />
            <label>
              English
              <input
                value={"English"}
                checked={ob.language === "English"}
                onClick={(e) => setob({ ...ob, language: "English" })}
                type="checkbox"
              />
            </label>
            <label>
              Hindi{" "}
              <input
                value={"Hindi"}
                checked={ob.language === "Hindi"}
                onClick={(e) => setob({ ...ob, language: "Hindi" })}
                type="checkbox"
              />
            </label>
            <label>
              Marathi{" "}
              <input
                value={"Marathi"}
                checked={ob.language === "Marathi"}
                onClick={(e) => setob({ ...ob, language: "Marathi" })}
                type="checkbox"
              />
            </label>
          </div>
          <div className="form-group">
            <textarea
              type="text"
              value={ob.feedback}
              onChange={(e) => setob({ ...ob, feedback: e.target.value })}
              className="form-control"
              id="textmessage4_std"
              placeholder="Feedbacks & Suggestions:"
              required
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
}
