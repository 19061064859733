import React, { Component } from 'react'
import Config from '../config'
import APICall from '../apiCall/api';
import moment from 'moment'
import '../admin/popup.css'

export default class Visitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vlist: [],
            purpose: "",
            dt: "",

            popupopen: false,
            rejectpopupopen: false,
            ob: {},
            sortkey:true
        }
        this.apiCall = new APICall();
    }
    approved() {
        console.log("savwit runnings", this.state.ob)
        const url = `${Config.API}visitor/approved`;
        this.apiCall.post(url, this.state.ob)
            .then(res => {
                console.log("resposnse after patch", res)
                this.setState({ popupopen: false });
                console.log(res);
                window.location.reload();
            })
        console.log("save runnings", this.state.ob)
    }

    componentDidMount() {
        const url = `${Config.API}visitor/get`;

        this.apiCall.get(url)
            .then(res => {
                this.setState({ vlist: res.sort((x,y)=>x.createdate-y.createdate) })
            })
    }
    clickReject  = (item) => {
        this.setState({ popupopen: true });
        this.setState({ ob: { ...item, status: "no" } })
        console.log("after reject status", this.state.ob)
    };
    handleClick = () => {
        this.setState({ popupopen: false })
    }
    handleClickforReject = () => {
        this.setState({ rejectpopupopen: false })
    }
    togglePop = (item) => {
        this.setState({ rejectpopupopen: true })
        this.setState({ ob: { ...item, status: "yes" } });
        console.log("approved runnings", this.state.ob);

    }
    handleFilter = () => {

    }
    onSort=(event,sortkey)=>{
      //const data=this.state.ob
     // console.log("data has",data);
     this.setState({sortkey:!this.state.sortkey})
      
      this.setState({vlist:this.state.vlist.sort((a,b) =>this.state.sortkey? a[sortkey]<b[sortkey]:b[sortkey]<a[sortkey])})
    }

    reject() {
        console.log("savwit runnings", this.state.ob)
        const url = `${Config.API}visitor/reject`;
        this.apiCall.post(url, this.state.ob)
            .then(res => {
                console.log("resposnse after patch", res)
                this.setState({ rejectpopupopen: false })
               window.location.reload();
            })
        console.log("save runnings", this.state.ob)
    }

    render() {
        let { purpose } = this.state
        return (
            <div className="container page_padding">
                <div id="enquiryid">
                    <div className="card">
                        <div className="card-header" id="enquiryheading">
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target="#collapseenquiry" aria-expanded="true" aria-controls="collapseenquiry">
                                    visitor_List
                                </button>
                            </h5>
                        </div>

                        <div id="collapseenquiry" className="collapse hide" aria-labelledby="enquiryheading" data-parent="#enquiryid">

                            <div className="card-body custom-card-body">
                                <div style={{ display: 'flex' }}>

                                    <div className="btn-group">
                                        <button onClick={e => this.setState({ purpose: "admission" })} type="button" class="btn btn-secondary">Admission</button>
                                        <button onClick={e => this.setState({ purpose: "marketing" })} type="button" class="btn btn-secondary">Marketing</button>
                                        <button onClick={e => this.setState({ purpose: "other" })} type="button" class="btn btn-secondary">Other</button>
                                        <button onClick={e => this.setState({ purpose: "" })} type="button" class="btn btn-primary">All</button>
                                    </div>
                                    <div> <label style={{ color: 'black ' }}><b>Select Date</b></label>
                                        <input
                                            value={this.state.dt}
                                            onChange={e => this.setState({ dt: e.target.value })}
                                            placeholder="sort by date" type="date"></input></div>
                                </div>
                                <br />
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="table-info">
                                            <th onClick={e => this.onSort(e, 'firstname')}scope="col">Fname </th>
                                            <th onClick={e => this.onSort(e, 'lastname')} scope="col">Lname </th>
                                            <th scope="col">PhoneNo </th>
                                            <th onClick={e => this.onSort(e, 'createdate')} scope="col">Date <i className="fa fa-sort"></i></th>
                                            <th scope="col">Intime </th>
                                            <th scope="col">Outtime </th>
                                            <th scope="col">Email </th>
                                            <th scope="col">purpose </th>
                                            <th scope="col">MeetingWith </th>
                                            <th colSpan="2">Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.vlist.reverse()
                                            .filter(x => x.purpose.includes(purpose))
                                            .filter(x => (x.createdate && x.createdate.startsWith(this.state.dt)))
                                            .map((item, index) => (
                                                <tr key={item.mobile} className={item.status === "yes" ? "table-success" : "table-danger"}>
                                                    <td>{item.firstname}</td>
                                                    <td>{item.lastname}</td>
                                                    <td>{item.mobile}</td>
                                                    <td>{moment(new Date(item.createdate).getTime()).format("YYYY/MM/DD hh:mm ")}</td>
                                                    <td>{item.intime}</td>
                                                    <td>{item.outtime}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.purpose}</td>
                                                    <td>{item.meetperson}</td>
                                                    {item.status === 'yes' && <td>Approved</td>}
                                                    {item.status === 'no' && <td>Rejected ({item.comment})</td>}
                                                    {item.status === '' && <td><button onClick={this.togglePop.bind(this, item)}
                                                        style={{ borderRadius: "5px", backgroundColor: "green" }}>Approve</button>
                                                    </td>}
                                                    {item.status === '' && <td><button onClick={this.clickReject.bind(this, item)}
                                                        style={{ borderRadius: "5px", backgroundColor: "red" }}>Reject</button></td>}
                                                </tr>

                                            ))}

                                    </tbody>
                                </table>


                            </div>
                        </div>
                    </div>
                </div>
                {/* ----popup area---- */}

                <div className={!this.state.popupopen ? "modal_two" : null}>
                    <div className="modal_content_two">
                        <span className="close" onClick={this.handleClick}>
                            &times;
                        </span>
                        <form>
                            <div className="modal_description_two" style={{ fontStyle: 'italic' }} >Please confirm the meeting.<br />If not then please provide suitable time slot as per your availability </div>
                            <label>
                                visitor's Name : {this.state.ob.firstname}
                            </label>
                            <br />
                            <label>
                                Intime of visitor:
                                <input onChange={e => this.setState({ ob: { ...this.state.ob, intime: e.target.value } })} type="time" value={this.state.ob.intime} name="intime" />
                            </label>
                            <div style={{ marginRight: '2%' }}></div>
                            <label>
                                Outtime of visitor:
                                <input onChange={e => this.setState({ ob: { ...this.state.ob, outtime: e.target.value } })} type="time" value={this.state.ob.outtime} name="outtime" />
                            </label><br />
                            <button onClick={e => this.reject()} className="btn-primary" type="button" >Confirm</button>
                            <button className="btn-primary" style={{ margin: '5%' }} type="submit" >Cancel</button>
                        </form>
                    </div>
                </div>

                <div className={!this.state.rejectpopupopen ? "modal_two" : null}>
                    <div className="modal_content_two">
                        <span className="close" onClick={this.handleClickforReject}>
                            &times;
                        </span>
                        <form>
                            <div className="modal_description_two">Are you Sure you want to Reject? </div>



                            <textarea className="form-control" rows="3" id="comment" placeholder="Your Comments*"
                                onChange={e => this.setState({ ob: { ...this.state.ob, comment: e.target.value } })}></textarea>
                            <br />
                            <button onClick={e => this.approved()} className="btn-primary" type="submit" >Confirm</button>
                            <button className="btn-primary" style={{ margin: '5%' }} type="submit" >Cancel</button>
                        </form>
                    </div>
                </div>

            </div>


        )
    }
}