import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { toast } from 'react-toastify';

import Config from '../config'
import APICall from '../apiCall/api';

class ScheduleAnnouncmentByClassOrStudent extends Component {
    constructor(props) {
        super(props);
        const defaultSubmissionDate = new Date();
        defaultSubmissionDate.setDate(defaultSubmissionDate.getDate() + 1);
        this.state = {
            allClass: [],
            studentsByClass: [],
            currentSession: {},
            classStudentskeyValueList: [],
            selectedClassInfo: {},
            scheduleDate: defaultSubmissionDate,
        }

        this.onClassSelection = this.onClassSelection.bind(this);
        this.onStudentSelection = this.onStudentSelection.bind(this);
        this.getStudentsByClass = this.getStudentsByClass.bind(this);
        this.handleScheduleDateChange = this.handleScheduleDateChange.bind(this);
        this.sendNotification = this.sendNotification.bind(this);
        this.apiCall = new APICall();
    }

    handleScheduleDateChange(date) {
        this.setState({
            scheduleDate: date
        });
    };

    componentDidMount() {
        this.getSession();
        this.getAllClass();
    }

    getSession() {
        const url = `${Config.API}session/get`;
        this.apiCall.get(url).then((data) => {
            const currentSession = data[0];
            this.setState({ currentSession });
        }).catch((err) => {
            console.log(err)
        });
    }

    getAllClass() {
        const url = `${Config.API}class/get`;
        this.apiCall.get(url).then((data) => {
            const allClass = data;
            this.setState({ allClass });
        }).catch((err) => {
            console.log(err)
        });
    }

    getStudentsByClass(classInfo) {
        const currentSessionId = this.state.currentSession._id;
        const url = `${Config.API}student/getByClass`;
        this.apiCall.post(url, { currentSessionId, classId: classInfo._id }).then((data) => {
            const updatedStudent = data.students.map((item) => {
                item.isThisStudentChecked = false;
                return item;
            });

            this.setState({ studentsByClass: updatedStudent, selectedClassInfo: classInfo });
        }).catch((err) => {
            console.log(err)
        });

    }

    onStudentSelection(e) {
        const selectedClassId = this.state.selectedClassInfo._id;
        let classStudentskeyValueList = this.state.classStudentskeyValueList;
        const isSelectedClassExist = classStudentskeyValueList.find(classStudent => classStudent.key === selectedClassId);
        const students = this.state.studentsByClass.map((item) => {
            if (item._id === e.target.value) {
                item.isThisStudentChecked = e.target.checked;
            }
            return item;
        });
        if (isSelectedClassExist) {
            const updatedClass = this.state.classStudentskeyValueList.map((item) => {
                if (item.class._id === this.state.selectedClassInfo._id) {
                    item.students = students;
                    const checkedStudents = students.filter(std=>(std.isThisStudentChecked));                    
                    item.isThisClassChecked = students.length === checkedStudents.length;
                }
                return item;
            })
            this.setState({ classStudentskeyValueList: updatedClass });
        } else {
            const selectedClassStudent = {
                key: selectedClassId,
                class: this.state.selectedClassInfo,
                students: students,
                isThisClassChecked: false
            };
            classStudentskeyValueList.push(selectedClassStudent);
            this.setState({ classStudentskeyValueList });
        }

    }

    onClassSelection(event) {
        const selectedClassId = this.state.selectedClassInfo._id;
        let classStudentskeyValueList = this.state.classStudentskeyValueList;
        const isSelectedClassExist = classStudentskeyValueList.find(classStudent => classStudent.key === selectedClassId);
        const students = this.state.studentsByClass.map((std) => {
            std.isThisStudentChecked = event.target.checked;
            return std;
        });

        if (isSelectedClassExist) {
            const updated = this.state.classStudentskeyValueList.map((item) => {
                if (item.class._id === selectedClassId) {
                    item.isThisClassChecked = event.target.checked;
                    item.students = students;
                }
                return item;
            });
            this.setState({ classStudentskeyValueList: updated });
        } else {
            const selectedClassStudent = {
                key: selectedClassId,
                class: this.state.selectedClassInfo,
                students: students,
                isThisClassChecked: event.target.checked
            };
            classStudentskeyValueList.push(selectedClassStudent);
            this.setState({ classStudentskeyValueList });
        }
    }

    sendNotification(e) {
        const scheduleDate = this.state.scheduleDate;
        const scheduleTime = this._selectedTime.value;
        const message = this._description.value;
        const students = this.state.classStudentskeyValueList.map(cls=>(cls.students));
        const selectedStudents = [];
        students.forEach((std)=>{
            const trueCheckedStudents = std.filter(s=>(s.isThisStudentChecked));
            selectedStudents.push(...trueCheckedStudents);
        });

        const url = `${Config.API}announcment/scheduleAnnouncment`;
        const options = {
            message,
            scheduleDate,
            scheduleTime, 
            students: selectedStudents 
        };
        this.apiCall.post(url, options).then((data) => {
            this.clearForm();
            toast.info('Success: scheduled notification!');
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
        
    }

    clearForm() {
        const defaultSubmissionDate = new Date();
        defaultSubmissionDate.setDate(defaultSubmissionDate.getDate() + 1);
        this._description.value = "";
        this._selectedTime.value = "7:00";
        this.setState({
            scheduleDate: defaultSubmissionDate,
            classStudentskeyValueList: []
        });

    }

    render() {
        const scheduleAnnouncmentHeading = "scheduleAnnouncmentHeading";
        const scheduleAnnouncmentHeadingCollapse = "scheduleAnnouncmentHeadingCollapse";

        return (
            <div className="container page_padding">
                <div id="accordion">
                    <div className="card">
                        <div className="card-header" id={scheduleAnnouncmentHeading}>
                            <h5 className="mb-0">
                                <button className="btn btn-link" data-toggle="collapse" data-target={`#${scheduleAnnouncmentHeadingCollapse}`} aria-expanded="true" aria-controls={scheduleAnnouncmentHeadingCollapse}>
                                    Schedule Announcment by Class or student(s)
                            </button>
                            </h5>
                        </div>


                        <div id={scheduleAnnouncmentHeadingCollapse} className="collapse hide" aria-labelledby={scheduleAnnouncmentHeading} data-parent="#accordion">

                            <div className="card-body custom-card-body">
                                <form>
                                    <div className="row">
                                        <div className="form-group col-12">
                                            <button type="button"
                                                className={`btn btn-primary ${this.state.isTodayAttendaceDone ? " cursorNotAllowed" : ""}`}
                                                disabled={this.state.isTodayAttendaceDone}
                                                onClick={this.sendNotification}
                                            >Schedule Notification</button>
                                        </div>
                                        <div className="form-group col-12">
                                            <div className="row">
                                                <label className="padding-10-right padding-15-left">Select Schedule Date</label>
                                                <DatePicker className="form-control"
                                                    selected={this.state.scheduleDate}
                                                    onChange={this.handleScheduleDateChange}
                                                    minDate={new Date()}
                                                />
                                                <select className="custom-select col-3 time-dropdown"
                                                    ref={(v) => this._selectedTime = v}>
                                                    {this.timeItem()}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group padding-15-left">
                                            <textarea rows="5" cols="85" className="form-control"
                                                placeholder="Enter Message" ref={(v) => this._description = v} required/>
                                        </div>
                                    </div>
                                    {this.renderClassList()}
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    timeItem() {
        const times = ['7:00', '8:00', '9:00', '10:00', '11:00', '12:00',
            '13:00', '14:00', '15:00', '16:00', '17:00', '18:00',
            '19:00', '20:00', '21:00', '22:00', '23:00']
        return times.map((item) => {
            return (
                <option value={item} key={item}>{item}</option>
            )
        });
    }

    renderClassList() {
        const accordionExample = "scheduleAnnouncment";

        return (<div className="accordion" id={accordionExample}>
            {this.state.allClass.map((cls, index) => {
                const headingOne = "scheduleAnnouncmentHeadingOne" + cls._id;
                const collapseOne = "scheduleAnnouncmentCollapseOne" + cls._id;
                const classStudentskeyValueList = this.state.classStudentskeyValueList;
                const selectedClass = classStudentskeyValueList.find(classStudent => classStudent.key === cls._id);
                const isThisClassChecked = selectedClass ? selectedClass.isThisClassChecked : false;

                return (<div className="card" key={index}>
                    <div className="card-header" id={headingOne}>
                        <h2 className="mb-0">
                            <button className="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target={`#${collapseOne}`} aria-expanded="true" aria-controls={collapseOne}
                                onClick={() => {
                                    this.getStudentsByClass(cls);
                                }}>
                                {cls.text}
                            </button>
                        </h2>
                    </div>

                    <div id={collapseOne} className="collapse hide" aria-labelledby={headingOne} data-parent={`#${accordionExample}`}>
                        <div className="card-body custom-card-body">
                            <div className="form-group form-check">
                                <input type="checkbox" className="form-check-input" checked={isThisClassChecked}
                                    onChange={(e) => this.onClassSelection(e)} />
                                <label className="form-check-label" htmlFor="exampleCheck1">select me to send notification to all students in this class</label>
                            </div>
                            <div className="form-group">
                                <ul className="list-group">
                                    {this.studentList(selectedClass, cls)}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                )
            })}
        </div>
        );
    }

    studentList(selectedClass, cls) {

        const list = this.state.studentsByClass.map((item, index) => {
            const selectedStudent = selectedClass ? selectedClass.students.find(std => std._id === item._id) : undefined;
            const isThisStudentChecked = selectedStudent ? selectedStudent.isThisStudentChecked : false;
            console.log(selectedStudent);

            return (
                <li className="list-group-item list-group-item-info" key={index}>
                    <div className="form-check row">
                        <label className="form-check-label col-10" htmlFor={item._id}>
                            {`${index + 1}. ${item.name}`}
                        </label>
                        <input className="form-check-input col-2" type="checkbox" value={item._id}
                            id={item._id} checked={isThisStudentChecked}
                            onChange={(e) => this.onStudentSelection(e)} />
                    </div>
                </li>
            )
        });

        return list;
    }
}

export default ScheduleAnnouncmentByClassOrStudent;