import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { toast } from 'react-toastify';
import Config from '../config'
import APICall from '../apiCall/api';


class PendingApprovalForDiscount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sessionList: [],
            classList: [],
            categoryList: [],
            currentSessionId: {},
            students: [],
            selectedClass: '',
            roles: JSON.parse(sessionStorage.getItem('roles')),
            selectedStudent:null,
            isDepositGreaterThanTotalFee: false,
            isDiscountGreaterThanTotalFee: false,
            totalFee: 0.0,
            remainingFee: 0.0,
            isDiscountApproved: false,
            discountList: []
        }
        this.onClassChangeGetStudent = this.onClassChangeGetStudent.bind(this);
        this.update = this.update.bind(this);
        this.onDepositChange = this.onDepositChange.bind(this);
        this.onDiscountChange = this.onDiscountChange.bind(this);
        this.setTotalFee = this.setTotalFee.bind(this);
        // this.setStatus = this.setStatus.bind(id);
        this.apiCall = new APICall();
    }

    setStatus(index) {
        const newStudents = [...this.state.students];
        newStudents[index].fee.isDiscountApproved = ! newStudents[index].fee.isDiscountApproved;
        this.setState({students: newStudents});

        const formData = new FormData();
        formData.append('classId', this.state.selectedClass);
        formData.append('sessionId', this.state.currentSessionId);
        formData.append('updatedStudent', JSON.stringify(newStudents[index]));
        // console.log(newStudents[index]);

        const url = `${Config.API}student/updateById`;
        this.apiCall.post(url, formData).then((data) => {
            toast.info('Success:Student updated!');
        }).catch((err) => {
            console.log(err)
            toast.error('Error: Failed to update!', {err});
        });

    }

    onDepositChange() {

        const checkCondition = (parseInt(this._depositFee.value) > parseInt(this.state.totalFee));
        this.setState({ isDepositGreaterThanTotalFee: checkCondition });

        // const remainingFee = (parseFloat(this.state.totalFee) - parseFloat(this._depositFee.value)).toFixed(2)
        const depositFee = this._depositFee && this._depositFee.value ? parseFloat(this._depositFee.value) : parseFloat(0);
        const discount = this._discount  && this._discount.value ? parseFloat(this._discount.value) : parseFloat(0);
        const remainingFee = (parseFloat(this.state.totalFee) - (depositFee + discount)).toFixed(2);

        this.setState({ remainingFee: remainingFee });

        if (checkCondition) {
            this._depositFee.value = "0";
        }
    }
    onDiscountChange() {

        const checkDiscountCondition = (parseInt(this._discount.value) > parseInt(this.state.totalFee));
        this.setState({ isDiscountGreaterThanTotalFee: checkDiscountCondition });

        const depositFee = this._depositFee && this._depositFee.value ? parseFloat(this._depositFee.value) : parseFloat(0);
        const discount = this._discount  && this._discount.value ? parseFloat(this._discount.value) : parseFloat(0);
        
        const remainingFee = (parseFloat(this.state.totalFee) - (depositFee + discount)).toFixed(2);

        this.setState({ remainingFee: remainingFee });

        if (checkDiscountCondition) {
            this._discount.value = "0";
        }
    }

    setTotalFee() {
        let totalFee = 0.0;
        try {
            const registrationFee = this._registrationFee ? parseFloat(this._registrationFee.value) : parseFloat(0);
            const admissionFees = this._admissionFees ? parseFloat(this._admissionFees.value) : parseFloat(0);
            const termFee = this._termFee ? parseFloat(this._termFee.value) : parseFloat(0);
            const tuitionFees = this._tuitionFees ? parseFloat(this._tuitionFees.value) : parseFloat(0);
            const transportFees = this._transportFees ? parseFloat(this._transportFees.value) : parseFloat(0);
            const higherClassFees = this._higherClassFees ? parseFloat(this._higherClassFees.value) : parseFloat(0);
            const middleClassFees = this._middleClassFees ? parseFloat(this._middleClassFees.value) : parseFloat(0);

            totalFee = (
                  registrationFee 
                + admissionFees
                + termFee
                + tuitionFees
                + transportFees
                + higherClassFees
                + middleClassFees
            ).toFixed(2)
                
        } catch (error) {
            console.log('Error while calculating total fees: ', error)
        }
        this.setState({ totalFee: totalFee});

        let remainingFee = 0.0;
        try {
            const depositFee = this._depositFee && this._depositFee.value ? parseFloat(this._depositFee.value) : parseFloat(0);
            const discount = this._discount && this._discount.value ? parseFloat(this._discount.value) : parseFloat(0);
            remainingFee = totalFee - (depositFee + discount)
        } catch (error) {
            console.log('Error while calculating remaining fee', error)
        }
        this.setState({ remainingFee: remainingFee });

    }


    componentDidMount() {
        this.getclass();
        this.getCategory();
        this.getSession()
    }

    onClassChangeGetStudent(event) {        
        this.setState({ selectedClass: this._selectedClass.value });
        this.setState({ currentSessionId: this._selectedSession.value });
        this.getStudentByClass(this._selectedClass.value, this._selectedSession.value);
    }

    getStudentByClass(classId, currentSessionId) {
        if (classId !== '') {
            const url = `${Config.API}student/getByClass`;
            this.apiCall.post(url, { currentSessionId, classId }).then((data) => {
                const students = data.students;
                this.setState({ students });

                this.state.students.map((item, index) => {
                    const discount = item.fee.discount ? parseFloat(item.fee.discount).toFixed(2) : 0.0;
                    this.setState({discountList: {[item._id]: discount}});
                    return null;
                });
                
            }).catch((err) => {
                console.log(err)
            });
        } else {
            this.setState({ students: [] });
        }
    }

    getSelectedStudent(studentId){
        this.setTotalFee();
        this.setState({ selectedStudent: null });
        const url = `${Config.API}student/getById`;
        const currentSessionId =  this.state.currentSessionId;
        const classId = this.state.selectedClass;
        
        this.apiCall.post(url, { currentSessionId, classId, studentId }).then((data) => {
            this.setState({ selectedStudent: data });
            this.setState({ totalFee: data.fee.totalFee ? data.fee.totalFee : 0.0 })
            this.setState({ remainingFee: data.fee.remainingFee ? data.fee.remainingFee : 0.0 })
            this.setState({ isDiscountApproved: data.fee.isDiscountApproved ? data.fee.isDiscountApproved : false })
    
        }).catch((err) => {
            console.log(err)
        });
    }

    render() {
        // const url = `${Config.API}student/downloaddExcelForStudentsDetails`;
        return <div className="container page_padding">
            {/* <a type="button" href={url} target="_blank" rel="noopener noreferrer" download>
                <button className="btn btn-outline-primary btn-sm mb-3">Export Students</button>
            </a> */}
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingFour">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                Student By Class
                            </button>
                        </h5>
                    </div>

                    <div id="collapseFour" className="collapse show" aria-labelledby="headingFour" data-parent="#accordion">
                        <div className="card-body custom-card-body">
                            <form>
                            <div className="form-group">
                                        <select className="custom-select"
                                            ref={(v) => this._selectedSession = v} required onChange={this.onClassChangeGetStudent}>
                                            <option value=''>Select Session</option>
                                            {this.sessionItem()}
                                        </select>
                                    </div>
                                <div className="form-group">
                                    <select className="custom-select"
                                        ref={(v) => this._selectedClass = v} required onChange={this.onClassChangeGetStudent}>
                                        <option value=''>Select Class</option>
                                        {this.classItem()}
                                    </select>
                                </div>
                                    { this._selectedClass?.value ?
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                            <th>#</th>
                                            <th>Students Name</th>
                                            <th>Total Fee</th>
                                            <th>Discount</th>
                                            <th>Is Approved</th>
                                            <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.studentList()}
                                        </tbody>
                                    </Table> : "" }
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="studentModal" tabIndex="-1" aria-labelledby="studentModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.showSelectedStudent()}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    classItem() {
        return this.state.classList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    categoryItem() {
        return this.state.categoryList.map((item) => {
            return (
                <option value={item.text} key={item._id}>{item.text}</option>
            )
        });
    }

    studentList() {

        const list = this.state.students.map((item, index) => {
            const discount = item.fee.discount ? parseFloat(item.fee.discount).toFixed(2) : 0.0;
            const isDiscount = discount > 0.0 ? true : false;
            
            return isDiscount && !item.fee.isDiscountApproved ?
                (
                <tr key={index}>
                    <td>{`${index + 1}`}</td>
                    <td>{`${item.name}`}</td>
                    <td>{`${item.fee.totalFee}`}</td>
                    <td>{`${item.fee.discount}`}</td>
                    <td><label className="form-check-label" onClick={()=>{this.setStatus(index)}}><i style={{cursor: "pointer"}} className={`far ${item.fee.isDiscountApproved ? "fa-check-circle text-success" : "fa-check-circle text-secondary"}`}></i></label></td>
                    <td><label className="form-check-label" data-toggle="modal" data-target="#studentModal" onClick={()=>{this.getSelectedStudent(item._id)}}><i style={{cursor: "pointer"}} className="far fa-edit"></i></label></td>
                </tr>) : null;
        });

        return list;
    }

    getclass() {
        const url = `${Config.API}class/get`;
        this.apiCall.get(url).then((data) => {
            const assignClassIds = [];
            this.state.roles.forEach(r => {
                if (r.assignClass) {
                    assignClassIds.push(r.assignClass._id);
                }
            });
            const classList = assignClassIds.length > 0 ? data.filter(d => assignClassIds.includes(d._id)) : data;

            this.setState({ classList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getCategory() {
        const url = `${Config.API}feecategory/get`;
        this.apiCall.get(url).then((data) => {
            const categoryList = data;
            this.setState({ categoryList });
        }).catch((err) => {
            console.log(err)
        });
    }

    getSession() {
        const url = `${Config.API}session/get`;
        this.apiCall.get(url).then((data) => {
            const sessionList = data;
            this.setState({ sessionList });
        }).catch((err) => {
            console.log(err)
        });
    }

    sessionItem() {
        return this.state.sessionList.map((item) => {
            return (
                <option value={item._id} key={item._id}>{item.text}</option>
            )
        });
    }

    getStudentList(data) {
        return data.map(d => ({ id: d._id, name: d.name, present: true, fee: d.fee }))
    }

    showSelectedStudent(){
        const {selectedStudent} = this.state;
        const student = selectedStudent;

        return student ? 
            <form id="selectedStudent"  encType="multipart/form-data">
                <Row>
                    <Col>    
                        <div className="form-group">
                            <label htmlFor="textmessage1_std">Enter Student Name</label>
                            <input type="text" className="form-control" id="textmessage1_std" 
                            placeholder="Enter Student Name" defaultValue={student.name} 
                            ref={(v) => this._studentName = v}
                            required />
                        </div>

                        <div className="form-group">
                            <label htmlFor="_feeCategory">Student's Fee Category</label>
                            <select className="custom-select" id="_feeCategory"
                                ref={(v) => this._feeCategory = v} defaultValue={student.feeCategory} required>
                                <option value=''>Select Student's Fee Category</option>
                                {this.categoryItem()}
                            </select>
                        </div>

                        <div className="form-group">
                            <label htmlFor="textmessage2_std">Enter Parent Name</label>
                            <input type="text" className="form-control" id="textmessage2_std" defaultValue={student.parent.name}
                                ref={(v) => this._parentName = v} required />
                        </div>
                        <div className="form-group">
                            <label htmlFor="textmessage3_std">Enter Parent Email</label>
                            <input type="email" className="form-control" id="textmessage3_std" defaultValue={student.parent.email}
                                ref={(v) => this._parentEmail = v} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="textmessage4_std">Enter Parent Phone of 10 digit</label>
                            <input type="tel" className="form-control" pattern="^\d{10}$" 
                            id="textmessage4_std" defaultValue={student.parent.phone}
                                ref={(v) => this._parentPhone = v} required />
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="rollNo">Roll No</label>
                            <input type="number" className="form-control" id="rollNo" defaultValue={student.rollNo}
                                ref={(v) => this._rollNo = v} />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary" onClick={this.update} data-dismiss="modal">Submit</button>
                        </div>

                    </Col>

                    {this.showFeeForm(student)}

                </Row>                
            </form> : ""
    }

    showFeeForm(student) {
        const rolesName = this.state.roles.map((role)=>{return role.roleName});
        const className = this.state.classList.find(cls => cls._id === this.state.selectedClass).text;

        return rolesName.includes("Admin") || rolesName.includes("Accountent") ?
            <Col>
            <Card bg="secondary" text="white" id='fees'>
                <Card.Header>Student's Fees Details</Card.Header>
                <Card.Body>
                    <div className="form-group">
                        <label htmlFor="_registrationFee">Enter Registration Fee</label>
                        <input type="number" className="form-control" id="_registrationFee" min="0" step="0.5" defaultValue={student.fee?.registrationFee || 0} ref={(v) => this._registrationFee = v} onChange={this.setTotalFee} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="_admissionFees">Enter Admission Fee</label>
                        <input type="number" className="form-control" id="_admissionFees" min="0" step="0.5" defaultValue={student.fee?.admissionFees || 0} ref={(v) => this._admissionFees = v} onChange={this.setTotalFee} />
                    </div>
                    <div className="form-group">
                    <label htmlFor="_termFee">Enter Term Fee</label>
                        <input type="number" className="form-control" id="_termFee" min="0" step="0.5" defaultValue={student.fee?.termFee || 0} ref={(v) => this._termFee = v} onChange={this.setTotalFee} />
                    </div>
                    <div className="form-group">
                    <label htmlFor="_tuitionFees">Enter Tuition Fee</label>
                        <input type="number" className="form-control" id="_tuitionFees" min="0" step="0.5" defaultValue={student.fee?.tuitionFees || 0} ref={(v) => this._tuitionFees = v} onChange={this.setTotalFee} />
                    </div>

                    {
                        ["Nursery A", "LKG A", "UKG A"].includes(className) ?
                                <div className="form-group">
                                    <label htmlFor="_transportFees">Enter Transport Fee</label>
                                    <input type="number" className="form-control" id="_transportFees" min="0" step="0.5" defaultValue={student.fee?.transportFees || 0} ref={(v) => this._transportFees = v} onChange={this.setTotalFee} />
                                </div>
                            : ["9th A", "10th A"].includes(className) ?
                                    <div className="form-group">
                                        <label htmlFor="_higherClassFees">Enter Higher Class Fee</label>
                                        <input type="number" className="form-control" id="_higherClassFees" min="0" step="0.5" defaultValue={student.fee?.higherClassFees || 0} ref={(v) => this._higherClassFees = v} onChange={this.setTotalFee} />
                                    </div>
                                :
                                    <div className="form-group">
                                        <label htmlFor="_middleClassFees">Enter Middle Class Fee</label>
                                        <input type="number" className="form-control" id="_middleClassFees" min="0" step="0.5" defaultValue={student.fee?.middleClassFees || 0} ref={(v) => this._middleClassFees = v} onChange={this.setTotalFee} />
                                    </div>
                    }

                    <div className="form-group">
                        <label htmlFor="_discount">Enter Discount</label>
                        <input type="number" className="form-control" id="_discount" min="0" step="0.5"
                            ref={(v) => this._discount = v} defaultValue={student.fee?.discount || 0} onChange={this.onDiscountChange} />
                        {this.state.isDiscountGreaterThanTotalFee && <label style={{ color: "#db3e00" }}>Discount should be less than Total Fee</label>}
                    </div>

                    <div className="form-group">
                        <label htmlFor="_depositFee">Enter Deposit Fee</label>
                        <input type="number" className="form-control" id="_depositFee" min="0" step="0.5"
                            ref={(v) => this._depositFee = v} defaultValue={student.fee?.depositFee || 0} onChange={this.onDepositChange} />
                        {this.state.isDepositGreaterThanTotalFee && <label style={{ color: "#db3e00" }}>Deposit Fee should be less than Total Fee</label>}

                    </div>

                    <div className="form-group">
                        <label htmlFor="_remainingFee">Remaining Fee</label>
                        <input type="number" className="form-control" id="_remainingFee" placeholder="Remaining Fee" value={this.state.remainingFee} readOnly={true}/>
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="_isDis">Is discount</label>
                        <input type="text" className="form-control" id="_isDis" placeholder="Discount" value={this.state.isDiscountApproved} readOnly={true}/>
                    </div> */}

                    <div className="form-group">
                        <label htmlFor="_totalFee">Total Fee</label>
                        <input type="number" className="form-control" id="_totalFee" placeholder="Total Fee" value={this.state.totalFee} readOnly={true}/>
                    </div>
                    

                </Card.Body>
            </Card>
            </Col>

        : ""
    }

    update(e) {
        e.preventDefault();
        const formData = this.makeStudentOption();
        const url = `${Config.API}student/updateById`;

        this.apiCall.post(url, formData).then((data) => {
            this.clearFormData();
            toast.info('Success:Student updated!');
        }).catch((err) => {
            console.log(err)
            toast.error('Error: Failed to update!', {err});
        });
    }

    makeStudentOption() {
        const student = {
            name: this._studentName.value,
            rollNo: this._rollNo.value,
            feeCategory: this._feeCategory.value,
            parent: {
                email: this._parentEmail.value,
                phone: this._parentPhone.value,
                name: this._parentName.value
            },
            fee: {
                registrationFee: this._registrationFee ? this._registrationFee.value : 0.0,
                admissionFees: this._admissionFees ? this._admissionFees.value : 0.0,
                termFee: this._termFee.value ? this._termFee.value : 0.0,
                tuitionFees: this._tuitionFees ? this._tuitionFees.value : 0.0,
                transportFees: this._transportFees ? this._transportFees.value : 0.0,
                higherClassFees: this._higherClassFees ? this._higherClassFees.value : 0.0,
                middleClassFees: this._middleClassFees ? this._middleClassFees.value : 0.0,
                totalFee: this.state.totalFee,
                remainingFee: this.state.remainingFee,
                depositFee: this._depositFee.value,
                discount: this._discount.value,
                isDiscountApproved: this.state.isDiscountApproved,
                paymentMode: 'Not defined yet'
            },
            _id: this.state.selectedStudent._id,
            updatedDate: new Date(),
            updateBy: sessionStorage.getItem('userName')
        }
        const formData = new FormData();
        formData.append('classId', this.state.selectedClass);
        formData.append('sessionId', this.state.currentSession._id);
        formData.append('updatedStudent', JSON.stringify(student));
        return formData;
    }

    clearFormData() {
        this._studentName.value = "";
        this._feeCategory.value = "";
        this._parentName.value = "";
        this._parentEmail.value = "";
        this._parentPhone.value = "";

        if (this._registrationFee) {
            this._registrationFee.value = this._registrationFee ? this._registrationFee.value : 0.0;
        }
        if (this._admissionFees) {
            this._admissionFees.value = this._admissionFees ? this._admissionFees.value : 0.0;
        }
        if (this._termFee) {
            this._termFee.value = this._termFee ? this._termFee.value : 0.0;
        }
        if (this._tuitionFees) {
            this._tuitionFees.value = this._tuitionFees ? this._tuitionFees.value : 0.0;
        }
        if (this._transportFees) {
            this._transportFees.value = this._transportFees ? this._transportFees.value : 0.0;
        }
        if (this._higherClassFees) {
            this._higherClassFees.value = this._higherClassFees ? this._higherClassFees.value : 0.0;
        }
        if (this._middleClassFees) {
            this._middleClassFees.value = this._middleClassFees ? this._middleClassFees.value : 0.0;
        }
        this._depositFee.value = "";
        this._discount.value = "";

        this._rollNo.value = "";
        this.setState({totalFee: 0.0})
        this.setState({remainingFee: 0.0})
    }
}


export default PendingApprovalForDiscount;
