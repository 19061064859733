import React, { Component } from 'react';
import Config from '../config'
import APICall from '../apiCall/api';

import logo from './header/logo.png';
import schoolName from './header/school_name.png';

import './getAttendenece.css';

class GetAttendence extends Component {

    constructor(props) {
        super(props);
        console.log('hello');
        const rollNo = props.location.search.split('=')[1];
        this.state = {
            rollNo,
            attendenec: []
        }
        this.apiCall = new APICall();
    }

    componentDidMount() {
        const url = `${Config.API}student/getTodayAttendance`;
        this.apiCall.post(url, { id: this.state.rollNo }).then((data) => {
            console.log('student updated', data);
            this.setState({ attendenec: data })
        }).catch((err) => {
            console.log(err);
        });
    }

    render() {
        const data = this.state.attendenec;
        if (!data) return <div>No attendenec</div>;
        return <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="#page-top">
                        <img className="svg d-inline-block align-top" src={logo} alt="school logo" />
                        <img className="school_name" src={schoolName} alt="school Name" />
                    </a>
                </div>
            </nav>
            <div className="container_get-attendnece ">
                <div className="row row_get-attendnece">
                    <div className="col-4 first thead_get-attendnece">Name</div>
                    <div className="col second">{data.name}</div>
                </div>
                <div className="row row_get-attendnece"  Style={"border-bottom:1px solid black"}>
                    <div className="col-4 first thead_get-attendnece">Class</div>
                    <div className="col second">{data.className}</div>
                </div>
                <div className="row">
                    <h5 className="col attendenece-title">Attendence</h5>
                </div>
                <div className="row row_get-attendnece">
                    <div className="col-4 first thead_get-attendnece">Subject</div>
                    <div className="col second thead_get-attendnece">Attendence</div>
                </div>
                
                {this.getAttendence(data)}
            </div>
        </div>
    }

    getAttendence(data) {
        if (!data.todayAttendance || data.todayAttendance.length === 0) { return ''; }
        return data.todayAttendance.map((d) => (
            <div className="row row_get-attendnece">
                <div className="col-4 first">{d.subject}</div>
                <div className="col second">{d.present ? this.getPresent() : this.getAbsent()}</div>
            </div>
        ));
    }

    getPresent() {
        return <i className="fas fa-1x fa-check"></i>
    }

    getAbsent() {
        return <i className="fas fa-1x fa-times"></i>
    }

    // <i className="fas fa-3x fa-gem icon_color mb-3 sr-icon-1"></i>
}

export default GetAttendence;