import React from 'react';
import "./contact.css";
import phoneSvg from "./assets/images/call.svg";
import messageSvg from "./assets/images/sms.svg";
import mapSvg from "./assets/images/map.svg";
import GoogleMapEmbed from "./GoogleMapEmbed"

const Contact = () => {
  return (
    <>
      <div
        className='main-contact-container'
      id="contact">
        <div className='left-contact'>
          <h3>Contact Us</h3>
          <h1>Let's Get In Touch!</h1>
          <p>
            Ready to start with us? That's great! Give us a call or send us an
            email and we will get back to you as soon as possible!
          </p>
          <div className='contact-details'>
            <div className='call'>
              <img src={phoneSvg} alt="" />
            </div>
            <p>9579383966 / 7276612890</p>
          </div>
          <div className='contact-details'>
            <div className='mail'>
              <img src={messageSvg} alt="" />
            </div>

            <p>admin@pioneerpublicschool.net</p>
          </div>
        </div>
        <div className="mapContainer">
          <GoogleMapEmbed />
          {/* <img className='mapImg' src={mapSvg} alt="" /> */}
          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.3888772443765!2d73.95559497413464!3d18.511320869458853!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c2133f025e7f%3A0x554ddd875607cff4!2sPioneer%20Public%20School!5e0!3m2!1sen!2sin!4v1689446716100!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
        </div>
      </div>
    </>
  );
}

export default Contact