import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Config from './config'
import APICall from './apiCall/api';

import 'bootstrap/dist/css/bootstrap.min.css';


class ModalPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: props.show,
        }
        this.handleClose = this.handleClose.bind(this);
        this.save = this.save.bind(this);
        this.apiCall = new APICall();
    }

    handleClose() {
        this.setState({ show: false });
    }

    save(e) {
        debugger
        e.preventDefault();
        console.log(this._name.value, this._phone.value, this._email.value);
        const options = {
            name: this._name.value,
            phone: this._phone.value,
            emailid: this._email.value,
            query: this._query.value,
            createdDate: new Date().toString()
        }
        const url = `${Config.API}contactus/create`;
        this.apiCall.post(url, options).then((data) => {
            this.clearFormData();
            this.setState({ show: false });
            toast.info('Success:Query submitted!');
        }).catch((err) => {
            console.log(err);
            toast.error('Error:Failed!');
        });
    }
    clearFormData() {
        this._name.value = "";
        this._phone.value = "";
        this._email.value = "";
        this._query.value = "";
}


    render() {
        return (
            <>
                <Modal show={this.state.show} onHide={this.handleClose} animation={false} backdrop='static'>
                    <Modal.Header closeButton>
                        <Modal.Title>Enquiry Form</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.save}>
                            <div className="form-group">
                                <input type="text" className="form-control" id="textmessage1_std" placeholder="Name *"
                                    ref={(v) => this._name = v} required />
                            </div>
                            <div className="form-group">
                                <input type="tel" className="form-control" pattern="^\d{10}$" id="textmessage4_std" placeholder="Mobile *"
                                    ref={(v) => this._phone = v} required />
                            </div>
                            <div className="form-group">
                                <input type="email" className="form-control" id="textmessage3_std" placeholder="Email"
                                    ref={(v) => this._email = v} />
                            </div>
                            <div className="form-group">
                                <textarea type="text" className="form-control" id="textmessage4_std" placeholder="Enter your query"
                                    ref={(v) => this._query = v} required/>
                            </div>
                            <div className="form-group">
                                        <button type="submit" className="btn btn-primary">Submit</button>
                            </div>

                        </form>
                        <p><span role="img" aria-label="thums up"></span></p>
                        <p className="badge badge-primary text-wrap" style={{fontSize:'13px'}}>Heartiest Cogratulations! 💐 to all the Students, Parents and Teachers of Pioneer Public School on achieving 100% result in CBSE class X board exam. School extends warmest wishes to each and every student and his/ her proud parents and good luck for future endeavors. We convey our heartfelt congratulations to our shining star Master Tanaji Lavate on making himself the milestone of PPS as the Topper of class X with an excellent score, the 1st batch of Pioneer Public School.</p>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default ModalPopUp;