import React, { Component } from 'react';
import data from './header.data.json';
import logo from './logo.png';
import schoolName from './school_name.png';
import './header.css';
import { Link, Redirect, withRouter } from 'react-router-dom';
import Appointment from '../appointment/appoinment';


class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.show1
        }
    }
    Handleclick = (e) => {
        alert("true")
        this.setState({ show: false })

        // this.props.history.push("/appform")

    }
    Handlevisitor=(e)=>{
        this.props.history.push('/visitor')
    }
    handleClose = (e) => {
        this.setState({ show: false })
    }
    render() {

        return (<>

            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <Appointment show={this.state.show} onHide={this.handleClose} />
                    <a className="navbar-brand" href="#page-top">
                        <img className="svg d-inline-block align-top" src={logo} alt="school logo" />
                        <img className="school_name" src={schoolName} alt="school Name" />
                    </a>
                    <a className="navbar-brand" href="#page-top">

                    </a>
                    <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                        aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto">
                            {this.getItemList()}


                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#services">Curriculum </a>
                                <ul>
                                    <li className="nav-item">
                                        <a href="#services" className="nav-link js-scroll-trigger" >Academic Calendar</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#services" className="nav-link js-scroll-trigger">Extra Curricular</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#services" className="nav-link js-scroll-trigger">Syllabus</a>
                                    </li>
                                </ul>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link js-scroll-trigger" href="#none">Other Links</a>
                                <ul>
                                    <li className="nav-item">
                                        <a href="teachers_details.docx" className="nav-link js-scroll-trigger" >Teachers Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="students_details.docx" className="nav-link js-scroll-trigger">Students Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="lc_details.docx" className="nav-link js-scroll-trigger">LC Details</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="e-prospectus.pdf" className="nav-link js-scroll-trigger">E-Prospectus</a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="career.jpg" className="nav-link js-scroll-trigger">Career</a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a onClick={() => this.setState({ show: true })} className="nav-link js-scroll-trigger">Appointment</a>
                                    </li> */}
                                    <li className="nav-item">
                                        <a onClick={this.Handlevisitor} className="nav-link js-scroll-trigger">visitor</a>
                                    </li>
                                </ul>
                            </li>



                        </ul>
                    </div>
                </div>
            </nav>
        </>
        )
    }


    getItemList() {
        return data.map((item) => (<li className="nav-item" key={item.key}>
            <a className="nav-link js-scroll-trigger" href={item.href}>{item.name}</a>
        </li>))
    }


}

export default withRouter(Header);
