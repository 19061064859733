import React, { Component } from 'react';
import PrinclipleImage from './principal.jpg';

class AboutUs extends Component {
    render() {
        return (
            <section className="page_padding section_background_color" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h3 className="section-heading text-center">About Us</h3>
                            <hr className="page_hr" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <p className="mb-4 section_paragraph">
                                Wide 80,000 Sq. ft. Area | 40,000 Sq. ft. Playground | A Library with rich collection | Safe, Secure and Lush Green Environment | Spacious Classrooms | Virtual Monitoring System | Well equipped Science & Computer Lab | Well trained and experienced staff | Transport facility with safety measures
                            </p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <p className="mb-4 section_paragraph margin_bottom_point_2_em">
                                <strong className="highlighted_color">Mr. Gyanesh Sharma (Founder) Message</strong>
                            </p>
                            <p className="mb-4 section_paragraph">
                                One of the best CBSE schools in Pune Schooling from nursery to XII 60,000 Sq Ft Play Ground Sports for All Compulsory
                                Spacious class rooms Safe Secure and lush green environment.
                            </p>
                        </div>
                        <div className="col-sm-6 embed-responsive embed-responsive-16by9">
                            <iframe title="about_you_tube" width="420" height="250" src="https://www.youtube.com/embed/QxZv-5s9Txc" frameBorder="0" allow="" allowFullScreen></iframe>
                        </div>
                    </div>

                    <div className="row margin_top_1_point_5_em">
                        <div className="col-sm-6">
                            <p className="mb-4 section_paragraph margin_bottom_point_2_em">
                                <strong className="highlighted_color">Mrs. Nidhika Gyanesh Sharma (Director) Message</strong>
                            </p>
                            <p className="mb-4 section_paragraph">
                                Our staff is working towards a clear sense of our goals and commitment to our school and the educational opportunity we will provide. We are striving for ALL kids to learn and grow.
                                It is important to me, as your principal, that everyone who steps through our doors are excited to be here and be a part of something new! Working together enables us all to meet the challenges and needs in a positive,
                                fun and nurturing environment. Please call our school when you have questions or concerns. We wish your child a happy and successful school year and look forward to this exciting journey with you all.
                            </p>
                            <p className="highlighted_color">
                                School timings Monday to Friday 8.30 am to 2.45 pm Saturday 9.00 am to 2.00 pm.
                            </p>
                        </div>
                        <div className="col-sm-6 embed-responsive embed-responsive-16by9">
                            <iframe title="about_you_tube" width="420" height="250" src="https://www.youtube.com/embed/Znezu8H5U68" frameBorder="0" allow="" allowFullScreen></iframe>
                        </div>
                    </div>

                    <div className="row margin_top_1_point_5_em">
                        <div className="col-sm-6">
                            <p className="mb-4 section_paragraph margin_bottom_point_2_em">
                                <strong className="highlighted_color">Mrs. Sangeeta Kakati (Principal) Message</strong>
                            </p>
                            <p className="mb-4 section_paragraph">
                                In the words of B.F Skinner. "Education is what survives when what has been learned has been forgotten." Surely, education should produce better individuals and better communities. It is not about gaining information and knowledge alone, but about enabling a change in behaviour that is beneficial to the society. This is especially important in today's context where there is so much discontent in the world.
                            </p>
                            <p className="section_paragraph">
                            At PioneerPublicSchool,Pune we stress on providing holistic education to our students, so that they can step out into the world tomorrow and become the leaders and torchbearers of their nation. We strive to provide opportunity to our students, to fire their curiosity and bring out their potential to the fullest. For this we have a host of curricular and co-curricular activities that students participate in. Additionally, our teachers are well equipped to handle students and their activities with professionalism mixed with tender care and attention. We are grateful for the cooperation of the parents of our students, whose support we value.
                            </p>
                        </div>
                        <div className="col-sm-6">
                            <img alt="pricipal" src={PrinclipleImage} width="103%"/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="service-box mt-5 mx-auto">
                                <h5 className="mb-3">Pre-Primary @Manjri</h5>
                                <p className="text-muted mb-0">Ghule Wasti, Manjri Road, Mahadev Nagar, Manjari, Pune 412307</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="service-box mt-5 mx-auto">
                                <h5 className="mb-3">Pre-Primary @Gopal Patti</h5>
                                <p className="text-muted mb-0">Kamal Vihar, Gopal Patti, Manjri Bk, Tal. Haveli, Pune 412307 </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="service-box mt-5 mx-auto">
                                <h5 className="mb-3">Senior Secondary @Manjri</h5>
                                <p className="text-muted mb-0">Near New Serum Institute Plant, Manjri, Pune 412307</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 text-center">
                            <div className="service-box mt-5 mx-auto">
                                <h5 className="mb-3">Primary @kolwadi</h5>
                                <p className="text-muted mb-0">Gat. No. 497, Bhalsing Wasti, Rd. Near Khandoba Temple, Tal. Haveli, Pune 412110</p>
                            </div>
                        </div>
                    </div>


                </div>
            </section>
        );
    }
}

export default AboutUs;