import React from 'react';
import "./Testinomials.css";
import person1 from "./assets/images/p1.svg";
import person2 from "./assets/images/p2.svg";
import person3 from "./assets/images/p3.svg";

const Testinomials = () => {
  return (
    <div className="testinomials-container">
      {/* <h3 style={{marginBottom:'50px'}}>ANNOUNCMENT</h3> */}
      <h3>TESTIMONIALS</h3>
      <h1>PARENTS TESTIMONIALS</h1>

      <div className="testnomials-cards">
        <div className="card">
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>

          <div className="profile">
            <img src={person1} alt="" />
            <h4>Anonymous</h4>
          </div>
        </div>
        <div className="card">
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>

          <div className="profile">
            <img src={person2} alt="" />
            <h4>Anonymous</h4>
          </div>
        </div>
        <div className="card">
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>

          <div className="profile">
            <img src={person3} alt="" />
            <h4>Anonymous</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testinomials