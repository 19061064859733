import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import imageOne from "./assets/images/slider1.jpg";
import sliderImg1 from './assets/grid/1.jpg'
import sliderImg2 from './assets/grid/2.jpg'
import "./HomeImageSlider.css";
import downloadButton from "./assets/images/download.svg"

const images = [{ url: sliderImg2 }, { url: sliderImg1 }, { url: sliderImg2 }, { url: sliderImg1 }];
const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

const HomeImageSlider = () => {

  const handleMouseOver = ()=>{
    let dropDownNav = document.querySelector('.otherLinksUl');
    if(dropDownNav){
      dropDownNav.classList.remove('active')
    }
  }
  return (
    <div
      className="sliderContainer"
      style={{
        position: "relative",
        zIndex: 0,
      }}
      onMouseOver={handleMouseOver}
    >
      <SimpleImageSlider
        width={ isMobile ? "116%": "100%"}
        height={"98vh"}
        images={images}
        showNavs={true}
        navStyle={1}
        autoPlay={true}
        bgColor="rgba(0, 0, 0, 0.3)"
        
      />

      <div className="silder-content">
        <div className="first-heading">
          <p> Discover Yourself at</p>
        </div>
        <h3 className="main-heading">PIONEER PUBLIC SCHOOL</h3>
        <p className="last-heading">
          It is a long established fact that a reader will be distracted by the{" "}
          <br />
          readable content of a page when looking at its layout.
        </p>

        <div className="act-buttons">
          
         <a href="/admission_form.pdf">
          <button>
            ADMISSION FORM (I to X) <img src={downloadButton} alt="" />
          </button> </a>

         <a href="/admission_form_XI.pdf">
          <button>
            ADMISSION FORM (XI) <img src={downloadButton} alt="" />
          </button> </a>

          <a href="/admission_procedure.pdf"> <button>
            ADMISSION PROCEDURE <img src={downloadButton} alt="" />
          </button></a>

        </div>
      </div>
      <div className="marquee-container">
        <marquee style={{color:'red'}} behavior="scroll" direction="left" scrollamount="18">
        For the academic session 2023-2024, now admissions are closed. Registration will start for the new academic year 2024-25 in the first week of December, 2023.
        </marquee>
      </div>
    </div>
  );
};

export default HomeImageSlider;
